import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";

const CustomButton = withStyles({
    root: {
        borderRadius: "4px", 
        letterSpacing: "1.25px",
     
    }
})(Button);

export default CustomButton;
