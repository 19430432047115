import { gql } from 'apollo-boost';

enum HELPMATE {
  HelpmateAnalytics = 'helpmateAnalytics',
  IndividualHelpmateAnalytics = 'individualHelpmateAnalytics',
}

export const helpmateAnalyticsQuery = gql`
    query ${HELPMATE.HelpmateAnalytics}{
        ${HELPMATE.HelpmateAnalytics}{
            statusCode
            message
            data{
              pendingHelpmate{
                totalPendingHelpmate
                percentage
              }
              accepetedHelpmate{
                totalAcceptedHelpmate
                percentage
              }
              rejectedHelpmate{
                totalRejectedHelpmate
                percentage
              }
            }
        }
    }
`;

export const IndividualHelpmateAnalyticsQuery = gql`
    query ${HELPMATE.IndividualHelpmateAnalytics}($helpmateId: String!){
        ${HELPMATE.IndividualHelpmateAnalytics}(helpmateId: $helpmateId){
            statusCode
            message
            data{
              completedHours
              pendingHours
              totalHours
              missedHours
            }
        }
    }
`;
