import {
  iIsValidEmailResponse,
  iLoginArgs,
  iOtpVerifyArgs,
  iSendOtpArgs,
  iSendOtpResponse,
  iSetNewPasswordArgs,
  iSetPasswordArgs,
  iSetPasswordNewResponse,
  iSetPasswordResponse,
} from 'types/onboard';
import { Mutation, Query } from 'config';
import {
  iGraphqlResponse,
  iIsValidEmailArgs,
  iIsValidEmailData,
  iLoginResponse,
  iOtpVerifyResponse,
  iResetPassword,
  iResetPasswordArgs,
  iResetPasswordResponse,
} from 'types';
import {
  loginMutation,
  validateOTPMutation,
  setPasswordMutation,
  sendOtpMutation,
  isValidEmailQuery,
  resetPasswordMutation,
  resetPasswordQuery,
} from '../graphql';
import { toaster } from 'helpers';

export const login = async (
  args: iLoginArgs,
  isLoading
): Promise<string | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(loginMutation, args)
    ) as iGraphqlResponse<iLoginResponse>);
    isLoading(false);
    return response.data.login.data.jwtToken;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const ValidateOTP = async (
  args: iOtpVerifyArgs
): Promise<String | null> => {
  try {
    const response = await ((
      await Mutation(validateOTPMutation, args)
    ) as iGraphqlResponse<iOtpVerifyResponse>);
    return response.data.validateOTP.message;
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};

export const setPassword = async (
  args: iSetPasswordArgs
): Promise<string | null> => {
  try {
    const response = await ((
      await Mutation(setPasswordMutation, args)
    ) as iGraphqlResponse<iSetPasswordResponse>);
    return response.data.setPassword.message;
  } catch (error: any) {
    toaster.error(error.message);
    return null;
  }
};

export const setNewPasswordApi = async (
  args: iSetNewPasswordArgs
): Promise<string | null> => {
  try {
    const response = await ((
      await Mutation(resetPasswordQuery, args)
    ) as iGraphqlResponse<iSetPasswordNewResponse>);
    return response.data.setPasswordNew.message;
  } catch (error: any) {
    toaster.error(error.message);
    return null;
  }
};

export const sendOtp = async (args: iSendOtpArgs): Promise<String | null> => {
  try {
    const response = await ((
      await Mutation(sendOtpMutation, args)
    ) as iGraphqlResponse<iSendOtpResponse>);
    return response.data.sendOTP.message;
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};

export const isValidEmail = async (
  args: iIsValidEmailArgs
): Promise<iIsValidEmailData | null> => {
  try {
    const response = await ((
      await Query(isValidEmailQuery, args)
    ) as iGraphqlResponse<iIsValidEmailResponse>);
    return response.data.isValidEmail.data;
  } catch (error: any) {
    console.log(error);
    // toaster.error(error.message);
    return null;
  }
};

export const resetPassword = async (
  args: iResetPasswordArgs
): Promise<iResetPassword | null> => {
  try {
    const response = await ((
      await Mutation(resetPasswordMutation, args)
    ) as iGraphqlResponse<iResetPasswordResponse>);
    return response.data.resetPassword;
  } catch (error) {
    console.log(error);
    return null;
  }
};
