export enum reviewedBy {
  BusinessUser = 'BusinessUser',
  Helpmate = 'Helpmate',
}

export enum issueCreatedBy {
  Helpmate = 'Helpmate',
  Business = 'Business',
}

export enum JobApplyType {
  Assigned = 'Assigned',
  applied = 'applied',
}