import RoutingPages from './routes/Routes';
import { Toaster } from 'react-hot-toast';
import { StoreProvider } from 'store';
import { initialState } from 'store/initialState/initialState';
import { rootReducer } from 'store/reducers/rootReducer';
import OfflineDetector from 'components/offlineDetector/offlineDetector';
// import GlobalStyles from "@mui/material/GlobalStyles";
import './App.scss';

export default function App(): JSX.Element {
  return (
    <div>
      {/* <GlobalStyles
        styles={{
          "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#1976d2",
            // outline: "1px solid slategrey"
          }
        }}
      /> */}
      <OfflineDetector>
        <Toaster
          containerStyle={{ zIndex: '100000000000000' }}
          toastOptions={{ className: 'toasterStyles' }}
        />
        <StoreProvider initialState={initialState} reducer={rootReducer}>
          <RoutingPages />
        </StoreProvider>
      </OfflineDetector>
    </div>
  );
}
