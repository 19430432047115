import AWS from 'aws-sdk';
// import { toaster } from 'helpers';

// const AWS_ACCESS_KEY_ID_ENV = 'AKIAZOXJTCTXRQP2JMQY';
// const AWS_SECRET_ACCESS_KEY_ENV = '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';
// const REGION = 'us-west-2';
// const BUCKET_NAME = 'getfaveo-dev-storage-v1';
const AWS_ACCESS_KEY_ID_ENV = process.env.REACT_APP_PROD_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY_ENV =
  process.env.REACT_APP_PROD_AWS_SECRET_ACCESS_KEY;
const REGION = process.env.REACT_APP_PROD_S3_REGION;
const BUCKET_NAME = process.env.REACT_APP_PROD_S3_BUCKET_NAME;

AWS.config.update({
  region: REGION,
  secretAccessKey: AWS_SECRET_ACCESS_KEY_ENV,
  accessKeyId: AWS_ACCESS_KEY_ID_ENV,
});

const s3 = new AWS.S3();

// const uploadValidation = ({
//   uploadType,
//   companyId,
//   userId,
// }: {
//   uploadType?: 'profile' | 'content' | 'profileThumbnail' | 'contentThumbnail';
//   companyId?: string;
//   userId?: string;
// }): boolean => {
//   if (uploadType === 'content' || uploadType === 'contentThumbnail') {
//     if (!companyId) {
//       toaster.error('Company Id required');
//       return false;
//     }
//   }
//   if (uploadType === 'profile' || uploadType === 'profileThumbnail') {
//     if (!userId) {
//       toaster.error('User Id required');
//       return false;
//     }
//   }
//   return true;
// };

export const s3Operations = {
  getObject: (params?: any) => {
    return new Promise((resolve, reject) => {
      s3.getObject({ ...params, Bucket: BUCKET_NAME }, (error, data) => {
        if (error) reject(error.stack);
        else {
          resolve(data);
        }
      });
    });
  },
  getObjects: (params?: any) => {
    return new Promise((resolve, reject) => {
      if (BUCKET_NAME) {
        s3.listObjectsV2({ ...params, Bucket: BUCKET_NAME }, (err, data) => {
          if (err) reject(err.stack);
          else {
            resolve(data);
          }
        });
      }
    });
  },
  upload: ({
    params,
    progress,
    companyId,
    userId,
    uploadType,
    contentType,
  }: {
    params: { Key: string; Body: any };
    progress?: (progress: number) => void;
    companyId?: string;
    userId?: string;
    contentType?: string;
    uploadType?:
      | 'profile'
      | 'companyLogo'
      | 'profileThumbnail'
      | 'contentThumbnail'
      | 'emptyStatesBannerImg'
      | 'helpmateProfile'
      | 'helpmateBackgroundPic';
  }) => {
    let Key = params?.Key?.split(' ')?.join('_').toLocaleLowerCase();
    // if (uploadType === 'content' || uploadType === 'contentThumbnail') {
    //   if (!companyId) {
    //     toaster.error('Company Id required');
    //     return;
    //   }
    // }

    // if (uploadType === 'profile' || uploadType === 'profileThumbnail') {
    //   if (!userId) {
    //     toaster.error('User Id required');
    //     return;
    //   }
    // }
    // if (uploadValidation({ uploadType, userId, companyId })) return;
    if (uploadType === 'profile') Key = `profile/${userId}/profiles/${Key}`;
    if (uploadType === 'profileThumbnail')
      Key = `profile/${userId}/thumbnails/${Key}`;

    if (uploadType === 'companyLogo')
      Key = `companyLogo/${companyId}/companyLogo${Key}`;
    if (uploadType === 'contentThumbnail')
      Key = `companyLogo/${companyId}/thumbnails${Key}`;
    if (uploadType === 'emptyStatesBannerImg') Key = `app_assets/${Key}`;

    return new Promise((resolve, reject) => {
      if (BUCKET_NAME) {
        s3.upload(
          {
            Bucket: BUCKET_NAME,
            ...params,
            Key,
            ContentType: contentType || params.Body.mimetype,
          },
          (error, response) => {
            if (error) {
              reject(error);
            } else {
              resolve(response);
            }
          }
        ).on('httpUploadProgress', ({ loaded, total }) => {
          if (progress) progress(Math.round((100 * loaded) / total));
        });
      }
    });
  },
  delete: (params: { Key: string }) => {
    return new Promise((resolve, reject) => {
      if (BUCKET_NAME) {
        s3.deleteObject(
          { Bucket: BUCKET_NAME, ...params },
          (error, response) => {
            if (error) {
              reject(error);
            } else {
              resolve(response);
            }
          }
        );
      }
    });
  },
};
