export enum STATUS {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
  started = 'started',
  review = 'review',
  ready = 'ready',
  completed = 'completed',
  cancelled = 'cancelled',
  Onboarded = 'Onboarded',
  Ongoing = 'Ongoing',
  declined = 'declined',
}

export enum SORTBY {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum jobApplicationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Ready = 'ready',
  Assigned = 'Assigned',
  Review = 'review',
  Reviewed = 'reviewed',
  Declined = 'declined',
  Cancelled = 'cancelled',
  Applied = 'applied',
}

