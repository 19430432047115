import { iBusinessState } from 'types';
import { BUSINESS, COMMON } from '../constants/index';
import { initialState } from '../initialState/initialState';

export const businessReducer = (
  state: iBusinessState,
  action
): iBusinessState => {
  switch (action.type) {
    case BUSINESS.getAllIndustries: {
      return { ...state, allIndustries: action.payload };
    }
    case BUSINESS.getAllBusiness: {
      return { ...state, allBusiness: action.payload };
    }
    case BUSINESS.setBusinessUserId: {
      return { ...state, businessUserId: action.payload };
    }
    case BUSINESS.setRole: {
      return { ...state, role: action.payload };
    }
    case BUSINESS.setBusinessId: {
      return { ...state, businessId: action.payload };
    }
    case BUSINESS.setSlots: {
      return { ...state, slots: action.payload };
    }
    case BUSINESS.setAdminBusinessId: {
      return { ...state, adminBusinessId: action.payload };
    }
    case BUSINESS.setFilterIds: {
      const temp = state.filterIds;
      if (state.filterIds.includes(action.payload)) {
        return {
          ...state,
          filterIds: temp.filter((id) => id !== action.payload),
        };
      }
      temp.push(action.payload);
      return { ...state, filterIds: temp };
    }
    case BUSINESS.clearFilterIds: {
      return { ...state, filterIds: [] };
    }
    case BUSINESS.setJobDetailsId: {
      return { ...state, jobDetailsId: action.payload };
    }
    case BUSINESS.setAllSkills: {
      return { ...state, allSkills: action.payload };
    }
    case BUSINESS.setJobSkills: {
      return { ...state, jobSkills: action.payload };
    }
    case BUSINESS.setApplicants: {
      return { ...state, applicants: action.payload };
    }
    case BUSINESS.invokeUpdateBusinessDetails: {
      return { ...state, updateBusinessDetails: !state.updateBusinessDetails };
    }
    case COMMON.reset: {
      return initialState.business;
    }
    default:
      return state;
  }
};
