const onBoarding = {
  login: '/Login',
  emailValidation: '/EmailValidation',
  forgotPassword: '/ForgotPassword',
  linkExpire: '/LinkExpire',
  passwordSetup: '/PasswordSetup',
  businessInfo: '/BusinessInfo',
  personalInfo: '/PersonalInfo',
  helpmateRegistration: '/HelpmateRegistration',
  helpmateResetPassword: '/HelpmateResetPassword',
  helpmateEmailCheck: '/HelpmateEmailCheck',
  helpmateResetPasswordWithOtp: '/HelpmateResetPasswordWithOtp',
  helpmatePasswordResetSuccessFull: '/HelpmatePasswordResetSuccessFull',
  sendInstructions: '/SendInstructions',
  contact: '/Contact',
  resetPassword: '/ResetPassword',
};
export const helpmate_Module = {
  helpmateAccountSetup: '/HelpmateAccountSetup',
  helpmateNavbar: '/HelpmateNavbar',
  jobFeed: '/JobFeed',
  helpmatePaymentDetails: '/HelpmatePaymentDetails',
  jobFeedBrowse: '/JobFeedBrowse',
  jobCompleted: '/JobCompleted',
  helpmateProfileSettings: '/HelpmateProfileSettings',
  helpmateJobBrowse: '/HelpmateJobBrowse',
  helpmateJobDetails: '/HelpmateJobDetails',
  helpmateJobReview: '/HelpmateJobReview',
  helpmateSupport: '/HelpmateSupport',
  helpmateIssueDetails: '/HelpmateIssueDetails',
  jobNearYou: '/JobNearYou',
  JobStatus: '/JobStatus',
  activeJobs: '/ActiveJobs',
  helpmateContactList: '/HelpmateContactList',
  helpmateMessageDetails: '/HelpmateMessageDetails',
  contactAdmin: '/ContactAdmin',
  helpmateAccountSetupSuccess: '/HelpmateAccountSetupSuccess',
  uppcomingJobDetail: '/UppcomingJobDetail',
  myNotifications: '/MyNotifications',
};
export const b2b_Dashboard = {
  createNewRequest: '/CreateNewRequest',
  requests: '/Requests',
  analytics: '/Analytics',
  billing: '/Billing',
  messaging: '/Messaging',
  jobDetails: '/JobDetails',
  helpmateProfilePending: '/HelpmateProfilePending',
  assignHelpmate: '/AssignHelpmate',
  assignHelpmateProfile: '/AssignHelpmateProfile',
  jobDetailsReview: '/JobDetailsReview',
  helpmateTimeEntries: '/HelpmateTimeEntries',
  paymentDetails: '/PaymentDetails',
  accountProfile: '/AccountProfile',
  acceptedHelpmateProfile: '/AcceptedHelpmateProfile',
  b2BSupport: '/B2BSupport',
  jobPostConfirm: '/JobPostConfirm'
};

export const customer_Success_manager = {
  customerManagerAccountProfile: '/CustomerManagerAccountProfile',
  analytic: '/Analytic',
  myBusiness: '/MyBusiness',
  updateBusinessStatus: '/UpdateBusinessStatus',
};

export const recruiter_or_hr = {
  recruiterAccountProfile: '/RecruiterAccountProfile',
  analyticsRecruiter: '/AnalyticsRecruiter',
  analyticsRecruiterRequestDetails: '/AnalyticsRecruiterRequestDetails',
  myHelpmateRequests: '/MyHelpmateRequests',
  adminHelpmateDetails: '/AdminHelpmateDetails',
  myHelpmateJobDetails: '/MyHelpmateJobDetails',
  historical: '/Historical',
  jobRequirementForm: '/JobRequirementForm',
  jobScheduleForm: '/JobScheduleForm',
  adminUpdateHelpmateStatus: '/AdminUpdateHelpmateStatus',
};

export const admin_module = {
  home: '/',
  adminAnalytics: '/AdminAnalytics',
  allBusiness: '/AllBusiness',
  helpmatesview: '/Helpmatesview',
  helpmateDetail: '/HelpmateDetail',
  adminBusinessDetails: '/AdminBusinessDetails',
  adminAccountProfile: '/AdminAccountProfile',
  createNewAdminRequest: '/CreateNewAdminRequest',
  adminPayment: '/AdminPayment',
  adminBillingDetails: '/AdminBillingDetails',
  adminDashboard: '/AdminDashboard',
  support: '/Support',
  dashboard: '/dashboard',
  adminHelpmateInformation: '/AdminHelpmateInformation',
  adminAddJob: '/AdminAddJob',
  adminNewJob: '/AdminNewJob',
  adminUsers: '/AdminUsers',
};

export const ROUTES = {
  ...onBoarding,
  ...b2b_Dashboard,
  ...customer_Success_manager,
  ...recruiter_or_hr,
  ...admin_module,
  ...helpmate_Module,
};

export const PUBLIC_ROUTES = [
  ROUTES.login,
  ROUTES.emailValidation,
  ROUTES.forgotPassword,
  ROUTES.linkExpire,
  ROUTES.passwordSetup,
  ROUTES.businessInfo,
  ROUTES.personalInfo,
];

export const PROTECTED_ROUTES = [];
