import * as React from "react"

const EyeIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 4C6.87 4 2.4 7.223 0 12c2.4 4.779 6.87 8 12 8s9.6-3.221 12-8c-2.4-4.777-6.87-8-12-8Zm0 13a5 5 0 1 1 0-10 5 5 0 0 1 0 10Z"
      fill="#000"
    />
    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#000" />
  </svg>
)

export default EyeIcon
