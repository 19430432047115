import { lazy, Suspense } from 'react';
import ProtectedRoutes from './ProtectedRoutes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ROUTES } from 'constants/paths';
import Loader from 'modules/B2BDashboard/Messaging/components/Loader/Loader';
import ResetPassword from 'modules/OnBoarding/ResetPassword/ResetPassword';
const Contact = lazy(() => import('modules/OnBoarding/OnBoard/Contact'));
const Login = lazy(() => import('modules/OnBoarding/Login/Login'));
const EmailValidation = lazy(() => import('modules/OnBoarding/EmailValidation/EmailValidation'));
const ForgotPassword = lazy(() => import('modules/OnBoarding/ForgotPassword/ForgotPassword'));
const PasswordSetup = lazy(() => import('modules/OnBoarding/PasswordSetup/PasswordSetup'));
const LinkExpire = lazy(() => import('modules/OnBoarding/LinkExpire/LinkExpire'));
const BusinessInfo = lazy(() => import('modules/OnBoarding/Registration/BusinessInfo/BusinessInfo'));
const PersonalInfo = lazy(() => import('modules/OnBoarding/Registration/PersonalInfo/PersonalInfo'));
const SendInstructions = lazy(() => import('modules/OnBoarding/SendInstruction/SendInstructions'));
const HelpmateRegistration = lazy(() => import('modules/Helpmates/Register/HelpmateRegister'));
const HelpmateResetPassword = lazy(() => import('modules/OnBoarding/Helpmate-Password-Reset/ResetPassword/ResetPassword'));
const HelpmateEmailCheck = lazy(() => import('modules/OnBoarding/Helpmate-Password-Reset/email-check/EmailCheck'));
const HelpmateResetPasswordWithOtp = lazy(() => import('modules/OnBoarding/Helpmate-Password-Reset/CreateNewPassword/ResetPassword'));
const HelpmatePasswordResetSuccessFull = lazy(() => import('modules/OnBoarding/Helpmate-Password-Reset/PasswordResetSuccessfull/ResetPasswordSuccessFull'));
const HelpmateDashboardLayout = lazy(() => import('components/Helpmates/HelpmateLayout/DashboardLayout'));
const JobFeed = lazy(() => import('modules/Helpmates/JobFeed/JobFeed'));
const HelpmateProfileSettings = lazy(() => import('modules/Helpmates/ProfileSettings/ProfileSettings'));
const HelpmateJobDetails = lazy(() => import('modules/Helpmates/HelpmateJobDetails/HelpmateJobDetails'));
const HelpmateAccountSetup = lazy(() => import('modules/Helpmates/AccountSetup/HelpmateAccountSetup'));
const HelpmateJobBrowse = lazy(() => import('modules/Helpmates/JobBrowse/JobBrowse'));
const HelpmatePaymentDetails = lazy(() => import('modules/Helpmates/Payments/Payments'));
const JobFeedBrowse = lazy(() => import('modules/Helpmates/jobfeedBrowse/JobFeedBrowse'));
const JobCompleted = lazy(() => import('modules/Helpmates/JobCompleted/JobCompleted'));
const HelpmateJobReview = lazy(() => import('modules/Helpmates/JobReview/JobReview'));
const HelpmateSupport = lazy(() => import('modules/Helpmates/Support/Support'));
const HelpmateIssueDetails = lazy(() => import('modules/Helpmates/Support/IssueDetail/IssueDetails'));
const JobNearYou = lazy(() => import('modules/Helpmates/JobNearYou/JobNearYou'));
const MyNotifications = lazy(() => import('modules/Helpmates/MyNotifications/MyNotifications'));
const JobStatus = lazy(() => import('modules/Helpmates/JobStatus/JobStatus'));
const ActiveJobs = lazy(() => import('modules/Helpmates/ActiveJobs/ActiveJobs'));
const HelpmateContactList = lazy(() => import('modules/Helpmates/Message/ContactList/HelpmateContactList'));
const HelpmateMessageDetails = lazy(() => import('modules/Helpmates/Message/MessageDetails/MessageDetails'));
const ContactAdmin = lazy(() => import('modules/Helpmates/Support/ContactAdmin/ContactAdmin'));
const HelpmateAccountSetupSuccess = lazy(() => import('modules/Helpmates/AccountSetup/HelpmateAccountSetupSuccess'));
const UppcomingJobDetail = lazy(() => import('modules/Helpmates/UppcomingJobDetail/UppcomingJobDetail'));
const DashboardLayout = lazy(() => import('components/B2BDashboard/DashboardLayout'));
const CreateNewRequest = lazy(() => import('modules/B2BDashboard/Request/NewRequest/CreateNewRequest'));
const Requests = lazy(() => import('modules/B2BDashboard/Request/Requests/Requests'));
const Analytics = lazy(() => import('modules/B2BDashboard/Analytics/Analytics'));
const Billing = lazy(() => import('modules/B2BDashboard/Billing/Billing'));
const JobPostConfirm = lazy(() => import('modules/B2BDashboard/Request/JobPostConfirm/JobPostConfirm'));
const Messaging = lazy(() => import('modules/B2BDashboard/Messaging/Messaging'));
const JobDetails = lazy(() => import('modules/B2BDashboard/Request/JobDetails/JobDetails'));
const HelpmateProfilePending = lazy(() => import('modules/B2BDashboard/Request/HelpmateProfile/HelpmateProfilePending'));
const AssignHelpmate = lazy(() => import('modules/B2BDashboard/Request/AssignHelpmate/AssignHelpmate'));
const AssignHelpmateProfile = lazy(() => import('modules/B2BDashboard/Request/AsignHelpmateProfile/AssignHelpmateProfile'));
const JobDetailsReview = lazy(() => import('modules/B2BDashboard/Request/JobReview/JobDetailsReview/JobDetailsReview'));
const HelpmateTimeEntries = lazy(() => import('modules/B2BDashboard/Request/HelpmateTimeEntries/HelpmateTimeEntries'));
const PaymentDetails = lazy(() => import('modules/B2BDashboard/Billing/InvoiceDetails/PaymentDetails'));
const AccountProfile = lazy(() => import('modules/B2BDashboard/Account/AccountProfile/AccountProfile'));
const AcceptedHelpmateProfile = lazy(() => import('modules/B2BDashboard/Request/AcceptedHelpmateProfile/AcceptedHelpmateProfile'));
const B2BSupport = lazy(() => import('modules/B2BDashboard/Support/B2BSupport'));
const CustomerManagerDashboardLayout = lazy(() => import('components/CustomerSucessManger/dashboard/CustomerManagerDashboardLayout'));
const CustomerManagerAccountProfile = lazy(() => import('modules/CustomerSucessManger/AccountProfile/CustomerManagerAccountProfile'));
const Analytic = lazy(() => import('modules/CustomerSucessManger/Analytics/Analytics'));
const MyBusiness = lazy(() => import('modules/CustomerSucessManger/MyBusiness/MyBusiness'));
const UpdateBusinessStatus = lazy(() => import('modules/CustomerSucessManger/UpdateBusinessStatus/UpdateBusinessStatus'));
const RecruiterOrHrDashboardLayout = lazy(() => import('components/RecruitersOrHr/Dashboard/RecruiterOrHrDashboardLayout'));
const RecruiterAccountProfile = lazy(() => import('modules/RecruitersOrHr/AccountProfile/RecruiterAccountProfile'));
const AnalyticsRecruiter = lazy(() => import('modules/RecruitersOrHr/Analytics/AnalyticsRecruiter'));
const AdminUpdateHelpmateStatus = lazy(() => import('modules/RecruitersOrHr/Analytics/RequestDetails/AdminUpdateHelpmateStatus'));
const AnalyticsRecuriterRequestDetail = lazy(() => import('modules/RecruitersOrHr/MyHelpmates/RequestDetail/AnalyticsRecuriterRequestDetail'));
const MyHelpmateRequests = lazy(() => import('modules/RecruitersOrHr/MyHelpmates/AdminPanelHelpmatesRequests/MyHelpmateRequests'));
const AdminHelpmateDetails = lazy(() => import('modules/RecruitersOrHr/MyHelpmates/MyHelpmatesOnboarded/AdminHelpmateDetails/AdminHelpmateDetails'));
const MyHelpmateJobDetails = lazy(() => import('modules/RecruitersOrHr/MyHelpmates/MyHelpmatesOnboarded/HelpmateJobDetail/MyHelpmateJobDetails'));
const Historical = lazy(() => import('modules/RecruitersOrHr/Historical/Historical'));
const JobRequirementForm = lazy(() => import('modules/RecruitersOrHr/MyHelpmates/RequestForm/JobRequirementForm'));
const JobScheduleForm = lazy(() => import('modules/RecruitersOrHr/MyHelpmates/JobScheduleForm/JobScheduleForm'));
const AdminDashboardLayout = lazy(() => import('components/AdminModule/AdminDashboardLayout'));
const AdminAnalytics = lazy(() => import('modules/AdminModule/AdminAnalytics/AdminAnalytics'));
const AllBusiness = lazy(() => import('modules/AdminModule/Business/AllBusiness/AllBusiness'));
const HelpmatesView = lazy(() => import('modules/AdminModule/Helpmates/HelpmatesView/HelpmatesView'));
const AdminBusinessDetails = lazy(() => import('modules/AdminModule/Business/BusinessDetail/AdminBusinessDetails'));
const AdminAccountProfile = lazy(() => import('modules/AdminModule/Account/AccountProfile/AdminAccountProfile'));
const CreateNewAdminRequest = lazy(() => import('modules/AdminModule/Business/AdminNewRequest/CreateNewAdminRequest'));
const AdminPayment = lazy(() => import('modules/AdminModule/Payment/AdminPayment'));
const AdminBillingDetails = lazy(() => import('modules/AdminModule/Payment/InvoiceDetails/AdminBillingDetails'));
const AdminDashboard = lazy(() => import('modules/AdminModule/AdminDashboard/AdminDashboard'));
const Support = lazy(() => import('modules/AdminModule/Support/Support'));
const AdminHelpmateInformation = lazy(() => import('modules/AdminModule/Helpmates/HelpmateDetail/AdminHelpmateInformation'));
const AdminAddJob = lazy(() => import('modules/AdminModule/AddJob/AdminAddJob'));
const AdminNewJob = lazy(() => import('modules/AdminModule/AddJob/NewJob/AdminNewJob'));
const AdminUsers = lazy(() => import('modules/AdminModule/Users/AdminUsers'));
const NotFound = lazy(() => import('modules/NotFound/NotFound'));
const Layout = lazy(() => import('components/Layout/Layout'));


const RoutingPages = (): JSX.Element => {
  return (
    // <HistoryRouter history={history}>
    <Router>
      <Suspense
        fallback={
          <div style={{margin: '0', padding: "0", textAlign: "center", justifyContent: "center"}}><Loader /></div>
        }
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route path={ROUTES.login} element={<Login />} />
            <Route path='/' element={<Login />} />
            <Route
              path={ROUTES.emailValidation}
              element={<EmailValidation />}
            />
            <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />
            <Route path={ROUTES.linkExpire} element={<LinkExpire />} />
            <Route path={ROUTES.passwordSetup} element={<PasswordSetup />} />
            <Route path={ROUTES.businessInfo} element={<BusinessInfo />} />
            <Route path={ROUTES.personalInfo} element={<PersonalInfo />} />
            <Route
              path={ROUTES.sendInstructions}
              element={<SendInstructions />}
            />
            <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
            {/* <Route
            path={ROUTES.helpmateAccountSetup}
            element={<HelpmateAccountSetup />}
          /> */}
            <Route
              path={ROUTES.helpmateRegistration}
              element={<HelpmateRegistration />}
            />
            <Route
              path={ROUTES.helpmateResetPassword}
              element={<HelpmateResetPassword />}
            />
            <Route
              path={ROUTES.helpmateEmailCheck}
              element={<HelpmateEmailCheck />}
            />
            <Route
              path={ROUTES.helpmateResetPasswordWithOtp}
              element={<HelpmateResetPasswordWithOtp />}
            />
            <Route
              path={ROUTES.helpmatePasswordResetSuccessFull}
              element={<HelpmatePasswordResetSuccessFull />}
            />
            <Route path={ROUTES.contact} element={<Contact />} />
          </Route>
          {/* protected routes  */}

          {/* <ProtectedRoutes> */}
          {/* <Route path="/" element={<ProtectedRoutes />}> */}
          {/* Helpmates Module */}
          {/* <Route path="/" element={<HelpmateDashboardLayout />}> */}
          {/* <Route path={ROUTES.jobFeed} element={<JobFeed />} /> */}
          <Route
            path="/"
            element={
              <ProtectedRoutes component={<HelpmateDashboardLayout />} />
            }
          >
            <Route
              path={ROUTES.jobFeed}
              element={<ProtectedRoutes component={<JobFeed />} />}
            />
            <Route
              path={ROUTES.helpmateProfileSettings}
              element={
                <ProtectedRoutes component={<HelpmateProfileSettings />} />
              }
            />
            <Route
              path={ROUTES.helpmateJobDetails}
              element={<ProtectedRoutes component={<HelpmateJobDetails />} />}
            />
            <Route
              path={ROUTES.helpmateAccountSetup}
              element={
                <ProtectedRoutes component={<HelpmateAccountSetup />} />
              }
            />
            <Route
              path={ROUTES.helpmateJobBrowse}
              element={<ProtectedRoutes component={<HelpmateJobBrowse />} />}
            />
            <Route
              path={ROUTES.helpmatePaymentDetails}
              element={<HelpmatePaymentDetails />}
            />
            <Route
              path={ROUTES.jobFeedBrowse}
              element={<ProtectedRoutes component={<JobFeedBrowse />} />}
            />
            <Route
              path={ROUTES.jobCompleted}
              element={<ProtectedRoutes component={<JobCompleted />} />}
            />
            <Route
              path={ROUTES.helpmateJobReview}
              element={<ProtectedRoutes component={<HelpmateJobReview />} />}
            />
            <Route
              path={ROUTES.helpmateSupport}
              element={<ProtectedRoutes component={<HelpmateSupport />} />}
            />
            <Route
              path={ROUTES.helpmateIssueDetails}
              element={
                <ProtectedRoutes component={<HelpmateIssueDetails />} />
              }
            />
            <Route
              path={ROUTES.jobNearYou}
              element={<ProtectedRoutes component={<JobNearYou />} />}
            />
            <Route
              path={ROUTES.JobStatus}
              element={<ProtectedRoutes component={<JobStatus />} />}
            />
            <Route
              path={ROUTES.activeJobs}
              element={<ProtectedRoutes component={<ActiveJobs />} />}
            />
            <Route
              path={ROUTES.helpmateContactList}
              element={
                <ProtectedRoutes component={<HelpmateContactList />} />
              }
            />
            <Route
              path={ROUTES.helpmateMessageDetails}
              element={
                <ProtectedRoutes component={<HelpmateMessageDetails />} />
              }
            />
            <Route
              path={ROUTES.contactAdmin}
              element={<ProtectedRoutes component={<ContactAdmin />} />}
            />
            <Route
              path={ROUTES.helpmateAccountSetupSuccess}
              element={
                <ProtectedRoutes
                  component={<HelpmateAccountSetupSuccess />}
                />
              }
            />
            <Route
              path={ROUTES.uppcomingJobDetail}
              element={<ProtectedRoutes component={<UppcomingJobDetail />} />}
            />
              <Route
              path={ROUTES.myNotifications}
              element={<ProtectedRoutes component={<MyNotifications />} />}
            />
          </Route>
          {/* </Route> */}

          {/* B2B Dashboard */}
          <Route
            path="/"
            element={<ProtectedRoutes component={<DashboardLayout />} />}
          >
            <Route
              path={ROUTES.createNewRequest}
              element={<ProtectedRoutes component={<CreateNewRequest />} />}
            />
            <Route
              path={ROUTES.requests}
              element={<ProtectedRoutes component={<Requests />} />}
            />
            <Route
              path={ROUTES.analytics}
              element={<ProtectedRoutes component={<Analytics />} />}
            />
            <Route
              path={ROUTES.billing}
              element={<ProtectedRoutes component={<Billing />} />}
            />
            <Route
              path={ROUTES.messaging}
              element={<ProtectedRoutes component={<Messaging />} />}
            />
            <Route
              path={ROUTES.jobDetails}
              element={<ProtectedRoutes component={<JobDetails />} />}
            />
            <Route
              path={ROUTES.helpmateProfilePending}
              element={
                <ProtectedRoutes component={<HelpmateProfilePending />} />
              }
            />
            <Route
              path={ROUTES.assignHelpmate}
              element={<ProtectedRoutes component={<AssignHelpmate />} />}
            />
            <Route
              path={ROUTES.assignHelpmateProfile}
              element={
                <ProtectedRoutes component={<AssignHelpmateProfile />} />
              }
            />
            <Route
              path={ROUTES.jobDetailsReview}
              element={<ProtectedRoutes component={<JobDetailsReview />} />}
            />
            <Route
              path={ROUTES.helpmateTimeEntries}
              element={
                <ProtectedRoutes component={<HelpmateTimeEntries />} />
              }
            />
            <Route
              path={ROUTES.paymentDetails}
              element={<ProtectedRoutes component={<PaymentDetails />} />}
            />
            <Route
              path={ROUTES.accountProfile}
              element={<ProtectedRoutes component={<AccountProfile />} />}
            />
            <Route
              path={ROUTES.acceptedHelpmateProfile}
              element={
                <ProtectedRoutes component={<AcceptedHelpmateProfile />} />
              }
            />
            <Route
              path={ROUTES.b2BSupport}
              element={<ProtectedRoutes component={<B2BSupport />} />}
            />
            <Route
              path={ROUTES.jobPostConfirm}
              element={<ProtectedRoutes component={<JobPostConfirm />} />}
            />
          </Route>
          {/* Customer Success Manager */}
          <Route path="/" element={<CustomerManagerDashboardLayout />}>
            <Route
              path={ROUTES.customerManagerAccountProfile}
              element={
                <ProtectedRoutes
                  component={<CustomerManagerAccountProfile />}
                />
              }
            />
            <Route
              path={ROUTES.analytic}
              element={<ProtectedRoutes component={<Analytic />} />}
            />
            <Route
              path={ROUTES.myBusiness}
              element={<ProtectedRoutes component={<MyBusiness />} />}
            />
            <Route
              path={ROUTES.updateBusinessStatus}
              element={
                <ProtectedRoutes component={<UpdateBusinessStatus />} />
              }
            />
          </Route>
          {/* Recruiter Or Hr DashboardLayout */}
          <Route path="/" element={<RecruiterOrHrDashboardLayout />}>
            <Route
              path={ROUTES.recruiterAccountProfile}
              element={
                <ProtectedRoutes component={<RecruiterAccountProfile />} />
              }
            />
            <Route
              path={ROUTES.analyticsRecruiter}
              element={<ProtectedRoutes component={<AnalyticsRecruiter />} />}
            />
            <Route
              path={ROUTES.adminUpdateHelpmateStatus}
              element={
                <ProtectedRoutes component={<AdminUpdateHelpmateStatus />} />
              }
            />
            <Route
              path={ROUTES.analyticsRecruiterRequestDetails}
              element={
                <ProtectedRoutes
                  component={<AnalyticsRecuriterRequestDetail />}
                />
              }
            />
            <Route
              path={ROUTES.myHelpmateRequests}
              element={<ProtectedRoutes component={<MyHelpmateRequests />} />}
            />
            <Route
              path={ROUTES.adminHelpmateDetails}
              element={
                <ProtectedRoutes component={<AdminHelpmateDetails />} />
              }
            />
            <Route
              path={ROUTES.myHelpmateJobDetails}
              element={
                <ProtectedRoutes component={<MyHelpmateJobDetails />} />
              }
            />
            <Route
              path={ROUTES.historical}
              element={<ProtectedRoutes component={<Historical />} />}
            />
            <Route
              path={ROUTES.jobRequirementForm}
              element={<ProtectedRoutes component={<JobRequirementForm />} />}
            />
            <Route
              path={ROUTES.jobScheduleForm}
              element={<ProtectedRoutes component={<JobScheduleForm />} />}
            />
          </Route>

          {/* Admin Module */}

          <Route path="/" element={<ProtectedRoutes component={<AdminDashboardLayout />} />}>
            <Route
              path={ROUTES.adminAnalytics}
              element={<ProtectedRoutes component={<AdminAnalytics />} />}
            />
            <Route path={ROUTES.allBusiness} element={<ProtectedRoutes component={<AllBusiness />} />} />
            <Route path={ROUTES.helpmatesview} element={<ProtectedRoutes component={<HelpmatesView />} />} />
            <Route
              path={ROUTES.adminBusinessDetails}
              element={<ProtectedRoutes component={<AdminBusinessDetails />} />}
            />
            <Route
              path={ROUTES.adminAccountProfile}
              element={<ProtectedRoutes component={<AdminAccountProfile />} />}
            />
            <Route
              path={ROUTES.adminBusinessDetails}
              element={<ProtectedRoutes component={<AdminBusinessDetails />} />}
            />
            <Route
              path={ROUTES.createNewAdminRequest}
              element={<ProtectedRoutes component={<CreateNewAdminRequest />} />}
            />
            <Route path={ROUTES.adminPayment} element={<ProtectedRoutes component={<AdminPayment />} />} />
            <Route
              path={ROUTES.adminBillingDetails}
              element={<ProtectedRoutes component={<AdminBillingDetails />} />}
            />
            <Route
              path={ROUTES.adminDashboard}
              element={<ProtectedRoutes component={<AdminDashboard />} />}
            />
            <Route path={ROUTES.support} element={<ProtectedRoutes component={<Support />} />} />
            <Route
              path={ROUTES.adminHelpmateInformation}
              element={<ProtectedRoutes component={<AdminHelpmateInformation />} />}
            />
            <Route path={ROUTES.adminAddJob} element={<ProtectedRoutes component={<AdminAddJob />} />} />
            <Route path={ROUTES.adminNewJob} element={<ProtectedRoutes component={<AdminNewJob />} />} />
            <Route path={ROUTES.adminUsers} element={<ProtectedRoutes component={<AdminUsers />} />} />
          </Route>
          {/* </Route> */}
          {/* </ProtectedRoutes> */}
          {/* catch all */}
          <Route path="*" element={<NotFound />} />
          {/* </Route> */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutingPages;
