import ApolloClient, { DocumentNode } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-unfetch';

// const ENDPOINT = 'http://localhost:3000/graphql';
// const ENDPOINT = 'https://api-dev.getfaveo.com/graphql';
const ENDPOINT = process.env.REACT_APP_PROD_API_URL
export const client = new ApolloClient({
  fetch,
  uri: ENDPOINT,
  cache: new InMemoryCache(),
  request: () => {
    //
  },
  onError: (error) => {
    console.log({ error });
  },
});

const STATUS_CODES = [200, 204, 201];
export const Mutation = async (
  mutation: DocumentNode,
  variables: any
): Promise<any> => {
  try {
    const response: any = await client.mutate({ mutation, variables });
    const key = Object.keys(response.data)[0];
    const { statusCode, message } = response.data[key] || {};

    if (!STATUS_CODES.includes(statusCode)) throw new Error(message);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const Query = async (
  query: DocumentNode,
  variables?: any
): Promise<any> => {
  try {
    const response: any = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });
    const key = Object.keys(response.data)[0];
    const { statusCode, message } = response.data[key] || {};
    // if (statusCode !== 200) throw new Error(message);
    if (!STATUS_CODES.includes(statusCode)) throw new Error(message);
    return response;
  } catch (error) {
    console.log();
    throw error;
  }
};
