import { Mutation, Query } from 'config';
import {
  applyJobMutation,
  getAllHelpmatesQuery,
  getFilteredHelpmateJobsQuery,
  getHelpmateDetailsQuery,
  getSearchedHelpmateJobsQuery,
  getLaborListQuery,
  updateHelpmateMutation,
  updateHelpmateStatusMutation,
  getSearchedHelpmatesQuery,
  getFilteredHelpmatesQuery,
  updateHelpmateProfileMutation,
  getMyHelpmateJobsQuery,
  getHelpmateNotificationQuery,
  saveTimesheetMutation,
  jobCompletedLaborListQuery,
  recentHelpmatesQuery,
  onBoardHelpmateStatusMutation,
} from '../graphql';
import {
  iGetHelpmateDetailsData,
  iGetHelpmateDetailsResponse,
  iGraphqlResponse,
  iGetHelpmateDetailsArgs,
  iUpdateHelpmateArgs,
  iUpdateHelpmateData,
  iUpdateHelpmateResponse,
  iApplyJobArgs,
  iApplyJobData,
  iApplyJobResponse,
  iGetFilteredHelpmateJobsArgs,
  iGetFilteredHelpmateJobsData,
  iGetHelpmateJobsResponse,
  iGetSearchedHelpmateJobsArgs,
  iGetSearchedHelpmateJobsResponse,
  iGetAllHelpmatesResponse,
  iGetAllHelpmatesData,
  iGetLaborListArgs,
  iGetLaborListData,
  iGetLaborListResponse,
  updateHelpmateStatusResponse,
  iUpdateHelpmateStatusArgs,
  iUpdateHelpmateStatusData,
  iSearchHelpmateArgs,
  iGetSearchedHelpmatesResponse,
  iGetFilteredHelpmatesArgs,
  iGetFilteredHelpmatesResponse,
  iupdateHelpmateProfileResponse,
  iUpdateHelpmateProfileArgs,
  iUpdateHelpmateProfileData,
  iGetMyHelpmateJobsArgs,
  iJobList,
  iGetMyHelpmateJobsResponse,
  iGetHelpmateNotificationArgs,
  iGetHelpmateNotficationData,
  iGetHelpmateNotificationResponse,
  iSaveTimesheetArgs,
  iSaveTimesheetData,
  iSaveTimesheetResponse,
  iJobCompletedLaborListData,
  jobCompletedLaborListResponse,
  iRecentHelpmatesArgs,
  iRecentHelpmatesResponse,
  iGetAllHelpmatesArgs,
  iGetHelpmates,
  iSearchHelpmatesData,
  iGetFilteredHelpmatesData,
  iOnBoardHelpmateArgs,
  iOnBoardHelpmateStatusResponse,
  iOnBoardHelpmateData,
} from 'types';
import { toaster } from 'helpers';

export const getHelpmateDetails = async (
  args: iGetHelpmateDetailsArgs,
  isLoading
): Promise<iGetHelpmateDetailsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getHelpmateDetailsQuery, args)
    ) as iGraphqlResponse<iGetHelpmateDetailsResponse>);
    isLoading(false);
    return response.data.getHelpmateDetails.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const updateHelpmate = async (
  args: iUpdateHelpmateArgs,
  isLoading
): Promise<iUpdateHelpmateData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(updateHelpmateMutation, args)
    ) as iGraphqlResponse<iUpdateHelpmateResponse>);
    isLoading(false);
    return response.data.updateHelpmate.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const applyJob = async (
  args: iApplyJobArgs,
  isLoading
): Promise<iApplyJobData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(applyJobMutation, args)
    ) as iGraphqlResponse<iApplyJobResponse>);
    isLoading(false);
    return response.data.applyJob.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const getFilteredHelpmateJobs = async (
  args: iGetFilteredHelpmateJobsArgs,
  isLoading
): Promise<iGetFilteredHelpmateJobsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getFilteredHelpmateJobsQuery, args)
    ) as iGraphqlResponse<iGetHelpmateJobsResponse>);
    isLoading(false);
    return response.data.getFilteredHelpmateJobs.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    return null;
  }
};

export const getSearchedHelpmateJobs = async (
  args: iGetSearchedHelpmateJobsArgs,
  isLoading
): Promise<iGetFilteredHelpmateJobsData | null> => {
  try {
    isLoading(true);
    const response = await (await Query(getSearchedHelpmateJobsQuery, args) as iGraphqlResponse<iGetSearchedHelpmateJobsResponse>);
    isLoading(false);
    return response.data.getSearchedHelpmateJobs.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    return null;
  }
};

export const getMyHelpmateJobs = async (
  args: iGetMyHelpmateJobsArgs,
  isLoading
): Promise<Array<iJobList> | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getMyHelpmateJobsQuery, args)
    ) as iGraphqlResponse<iGetMyHelpmateJobsResponse>);
    isLoading(false);
    return response.data.getAllHelpmateJobs.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getLaborList = async (
  args: iGetLaborListArgs,
  isLoading
): Promise<iGetLaborListData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getLaborListQuery, args)
    ) as iGraphqlResponse<iGetLaborListResponse>);
    isLoading(false);
    return response.data.getLaborList.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error);
    return null;
  }
};
export const jobCompletedLaborList = async (
  args: iGetLaborListArgs,
  isLoading
): Promise<iJobCompletedLaborListData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(jobCompletedLaborListQuery, args)
    ) as iGraphqlResponse<jobCompletedLaborListResponse>);
    isLoading(false);
    return response.data.jobCompletedLaborList.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error);
    return null;
  }
};

export const getAllHelpmates = async (
  args: iGetAllHelpmatesArgs,
  isLoading
): Promise<iGetHelpmates | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getAllHelpmatesQuery, args)
    ) as iGraphqlResponse<iGetAllHelpmatesResponse>);
    isLoading(false);
    return response.data.getAllHelpmates.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    return null;
  }
};

export const recentHelpmates = async (
  args: iRecentHelpmatesArgs,
  isLoading
): Promise<Array<iGetAllHelpmatesData> | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(recentHelpmatesQuery, args)
    ) as iGraphqlResponse<iRecentHelpmatesResponse>);
    isLoading(false);
    return response.data.recentHelpmates.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};

export const getSearchedHelpmates = async (
  args: iSearchHelpmateArgs,
  isLoading
): Promise<iSearchHelpmatesData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getSearchedHelpmatesQuery, args)
    ) as iGraphqlResponse<iGetSearchedHelpmatesResponse>);
    isLoading(false);
    return response.data.searchHelpmates.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    return null;
  }
};

export const getFilteredHelpmates = async (
  args: iGetFilteredHelpmatesArgs,
  isLoading
): Promise<iGetFilteredHelpmatesData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getFilteredHelpmatesQuery, args)
    ) as iGraphqlResponse<iGetFilteredHelpmatesResponse>);
    isLoading(false);
    return response.data.getFilteredHelpmates.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    return null;
  }
};

export const updateHelpmateStatus = async (
  args: iUpdateHelpmateStatusArgs,
  isLoading
): Promise<iUpdateHelpmateStatusData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(updateHelpmateStatusMutation, args)
    ) as iGraphqlResponse<updateHelpmateStatusResponse>);
    isLoading(false);
    return response.data.updateHelpmateStatus.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const updateHelpmateProfile = async (
  args: iUpdateHelpmateProfileArgs,
  isLoading
): Promise<iUpdateHelpmateProfileData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(updateHelpmateProfileMutation, args)
    ) as iGraphqlResponse<iupdateHelpmateProfileResponse>);
    isLoading(false);
    return response.data.updateHelpmate.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    return null;
  }
};

export const getHelpmateNotification = async (
  args: iGetHelpmateNotificationArgs,
  isLoading
): Promise<Array<iGetHelpmateNotficationData> | null> => {
  try {
    isLoading(true);
    const response = await (await Query(getHelpmateNotificationQuery, args) as iGraphqlResponse<iGetHelpmateNotificationResponse>);
    isLoading(false);
    return response.data.getAllHelpmateNotifi.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const saveTimesheet = async (
  args: iSaveTimesheetArgs,
  isLoading
): Promise<iSaveTimesheetData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(saveTimesheetMutation, args)
    ) as iGraphqlResponse<iSaveTimesheetResponse>);
    isLoading(false);
    return response.data.saveTimesheet.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const updateOnboardHelpmateStatus = async (
  args: iOnBoardHelpmateArgs,
  isLoading
): Promise<iOnBoardHelpmateData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(onBoardHelpmateStatusMutation, args)
    ) as iGraphqlResponse<iOnBoardHelpmateStatusResponse>);
    return response.data.onBoardHelpmate.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};
