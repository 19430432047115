import { gql } from 'apollo-boost';

export enum Helpmate {
  GetHelpmateDetails = 'getHelpmateDetails',
  UpdateHelpmate = 'updateHelpmate',
  ApplyJob = 'applyJob',
  GetFilteredHelpmateJobs = 'getFilteredHelpmateJobs',
  GetSearchedHelpmateJobs = 'getSearchedHelpmateJobs',
  GetAllHelpmates = 'getAllHelpmates',
  GetLaborList = 'getLaborList',
  updateHelpmateStatus = 'updateHelpmateStatus',
  SearchHelpmates = 'searchHelpmates',
  GetFilteredHelpmates = 'getFilteredHelpmates',
  GetAllHelpmateJobs = 'getAllHelpmateJobs',
  GetAllHelpmateNotifi = 'getAllHelpmateNotifi',
  SaveTimesheet = 'saveTimesheet',
  RecentHelpmates = 'recentHelpmates',
  JobCompletedLaborList = 'jobCompletedLaborList',
  OnBoardHelpmate = 'onBoardHelpmate',
}

export const getHelpmateDetailsQuery = gql`
  query ${Helpmate.GetHelpmateDetails}($helpmateId: String!){
    ${Helpmate.GetHelpmateDetails}(helpmateId: $helpmateId){
      message
      statusCode
      data {
        id
        name
        lastName
        mobileNumber
        email
        status
        createdAt
        role
        referedFrom
        profilePic
        bgPic
        about
        position{
          id
          name
        }
        yearOfExperience
        dayOfWeek
        settings{
          id
          notificationEmail
          notificationPush
          textMessage
          pohoneCall
          chatNotificationEmail
          chatNotificationPush
          jobReminder2Hours
          jobReminder24Hours
          jobReminder72Hours
          createdAt
          updateAt
        }
        location{
          id
          lat
          long
          address
          city
        }
        skills
        rating
        completedJobCount
        mile
      }
    }
  }
`;

export const updateHelpmateMutation = gql`
  mutation ${Helpmate.UpdateHelpmate}(
    $id: String!
    $name: String
    $lastName: String
    $referedFrom: String
    $email: String
    $mobileNumber: String
    $profilePic: String
    $about: String
    $position: [positionInput]
    $yearOfExperience: String
    $skills: [String]
    $dayOfWeek: String
    $location: locationInput1
    $bgPic: String
  ){
    ${Helpmate.UpdateHelpmate}(
      id: $id
      name: $name
      bgPic: $bgPic
      lastName: $lastName
      referedFrom: $referedFrom
      email: $email
      mobileNumber: $mobileNumber
      profilePic: $profilePic
      about: $about
      position: $position
      yearOfExperience: $yearOfExperience
      skills: $skills
      dayOfWeek: $dayOfWeek
      location: $location
    ){
      statusCode
      message
      data{
        id
        name
        email
        referedFrom
        lastName
        mobileNumber
        position{
          id
          name
        }
        profilePic
        yearOfExperience
        dayOfWeek
        skills
        settings{
          id
          notificationEmail
          notificationPush
          textMessage
          pohoneCall
          chatNotificationEmail
          chatNotificationPush
        }
        location{
          id
          address
          lat
          long
        }
      }
    }
  }
`;

export const applyJobMutation = gql`
  mutation ${Helpmate.ApplyJob}(
    $helpmateId: String
    $businessId: String
    $jobId: String
    $type: JobApplyType!
  ){
    ${Helpmate.ApplyJob}(
      helpmateId: $helpmateId
      businessId: $businessId
      jobId: $jobId
      type: $type
    ){
      statusCode
      message
      data{
        id
        helpmate
        businessId
        jobId
        status
      }
    }
  }
  `;

export const getMyHelpmateJobsQuery = gql`
  query ${Helpmate.GetAllHelpmateJobs}(
    $id: String!
    $sortBy: SortType
  ){
    ${Helpmate.GetAllHelpmateJobs}(
      id: $id
      sortBy: $sortBy
    ){
      message
      statusCode
      data{
        status
        id
        jobName,
          reportingTo,
          sectionName,
          payment,
          position{
            id
            name
          },
          noOfWorkers,
          yearOfExperience,
          description,
          skills,
          equipment,
          covid19,
          startDate,
          endDate,
          totalLaborHours,
          dayOfWeek,
          pointContact,
          userId,
          createdAt,
          business{
            id
            name
            logo
          },
          location{
            lat
            long
            id
          address
          }
          rating
          totalHours
      }
    }
  }
  `;

export const getSearchedHelpmateJobsQuery = gql`
query ${Helpmate.GetSearchedHelpmateJobs}(
  $searchKey: String!
  $skip: Int
  $limit: Int
){
  ${Helpmate.GetSearchedHelpmateJobs}(
    searchKey: $searchKey
    skip: $skip
    limit: $limit
  ){
    message
    statusCode
    data{
        count
        jobList{
          status
          id
          jobName,
    		  reportingTo,
    		  sectionName,
    		  payment,
    		  position{
      		  id
      		  name,
    		  }
    		  noOfWorkers,
    		  yearOfExperience,
    		  description,
    		  skills,
          business{
            id
            name
            logo
          }
    		  equipment,
    		  covid19,
    		  startDate,
    		  endDate,
    		  totalLaborHours,
    		  dayOfWeek,
    		  pointContact,
    		  userId,
    		  business{
            id
            name
          },
      	  location{
        	  lat
        	  long
        	  id
        	  address
      	  }
          createdAt
    	  }
      }
  }
}
`;

export const getFilteredHelpmateJobsQuery = gql`
  query ${Helpmate.GetFilteredHelpmateJobs}(
    $filterType: String
    $filter: String
    $skip: Int
    $limit: Int
  ){
    ${Helpmate.GetFilteredHelpmateJobs}(
      filterType: $filterType
      filter: $filter
      skip: $skip
      limit: $limit
    ){
      message
    statusCode
    data{
        count
        jobList{
          status
          id
          jobName,
    		  reportingTo,
    		  sectionName,
    		  payment,
    		  position{
      		  id
      		  name,
    		  }
    		  noOfWorkers,
    		  yearOfExperience,
    		  description,
    		  skills,
          business{
            id
            name
            logo
          }
    		  equipment,
    		  covid19,
    		  startDate,
    		  endDate,
    		  totalLaborHours,
    		  dayOfWeek,
    		  pointContact,
    		  userId,
    		  business{
            id
            name
          },
      	  location{
        	  lat
        	  long
        	  id
        	  address
      	  }
          createdAt
    	  }
      }
    
    }
  }
`;

export const getAllHelpmatesQuery = gql`
  query ${Helpmate.GetAllHelpmates}(
    $skip: Int
    $limit: Int
    $jobId: String
  ){
    ${Helpmate.GetAllHelpmates}(
      skip: $skip
      limit: $limit
      jobId: $jobId
    ){
      statusCode
      message
      data{
        count
        helpmateList {
          id
          name
          lastName
          mobileNumber
          email
          role
          referedFrom
          enabled
          profilePic
          bgPic
          verified
          mobileNumberVerified
          about
          createdAt
          status
          position{
            id
            name
          }
          skills
          rating
          yearOfExperience
          dayOfWeek
          settings{
            id
            notificationEmail
            notificationPush
            textMessage
            pohoneCall
            chatNotificationPush
            chatNotificationEmail
          }
          location{
            lat
            long
            address
            id
          }
        }
      }
    }
  }
`;

export const getSearchedHelpmatesQuery = gql`
  query ${Helpmate.SearchHelpmates}(
    $searchKey: String!
    $status: String!
    $skip: Int
    $limit: Int
    $jobId: String
  ){
    ${Helpmate.SearchHelpmates}(
      searchKey: $searchKey
      status: $status
      skip: $skip
      limit: $limit
      jobId: $jobId
    ){
      message
      statusCode
      data{
        count
        helpmateList{
          id
          name
          lastName
          mobileNumber
          email
          role
          referedFrom
          enabled
          profilePic
          bgPic
          verified
          mobileNumberVerified
          about
          position{
            id
            name
          }
          skills
          rating
          yearOfExperience
          dayOfWeek
          settings{
            id
            notificationEmail
            notificationPush
            textMessage
            pohoneCall
            chatNotificationPush
            chatNotificationEmail
          }
          location{
            lat
            long
            address
            id
          }
          status
          createdAt
        }
      }
    }
  }
`;

export const recentHelpmatesQuery = gql`
  query ${Helpmate.RecentHelpmates}(
    $businessId: String!
    $jobId: String
  ){
    ${Helpmate.RecentHelpmates}(
      businessId: $businessId
      jobId: $jobId
    ){
      statusCode
      message
      data{
            id
        name
        email
        referedFrom
        lastName
        rating
        mobileNumber
        about
        position{
          id
          name
        }
        profilePic
        bgPic
        yearOfExperience
        dayOfWeek
        skills
        settings{
          id
          notificationEmail
          notificationPush
          textMessage
          pohoneCall
          chatNotificationEmail
          chatNotificationPush
        }
        location{
          id
          address
          lat
          long
        }
      }
    }
  }
`;

export const getFilteredHelpmatesQuery = gql`
  query ${Helpmate.GetFilteredHelpmates}(
    $filterType: String
    $filter: String
    $skip: Int
    $limit: Int
    $jobId: String
  ){
    ${Helpmate.GetFilteredHelpmates}(
      filterType: $filterType
      filter: $filter
      skip: $skip
      limit: $limit
      jobId: $jobId
    ){
      message
      statusCode
      data{
        count
        helpmateList{
          createdAt
          status
          id
          name
          lastName
          mobileNumber
          email
          role
          referedFrom
          enabled
          profilePic
          bgPic
          verified
          mobileNumberVerified
          about
          position{
            id
            name
          }
          skills
          rating
          yearOfExperience
          dayOfWeek
          settings{
            id
            notificationEmail
            notificationPush
            textMessage
            pohoneCall
            chatNotificationPush
            chatNotificationEmail
          }
          location{
            lat
            long
            address
            id
          }
        }
      }
    }
  }
`;

export const getLaborListQuery = gql`
  query ${Helpmate.GetLaborList}($id: String!){
    ${Helpmate.GetLaborList}(id: $id){
    message
    statusCode
    data{
      id
      helpmate{
        id
        name
        lastName
        about
        profilePic
        bgPic
        skills
        position{
          id
          name
        }
        rating
      }
      jobId
      businessId
      status
      }
    }
  }
`;

export const jobCompletedLaborListQuery = gql`
  query ${Helpmate.JobCompletedLaborList}($id: String!){
    ${Helpmate.JobCompletedLaborList}(id: $id){
    message
    statusCode
    data{
      id
      name
      profilePic
      position{
        id
        name
      }
         totalHours
      }
    }
  }
`;

export const updateHelpmateStatusMutation = gql`
  mutation ${Helpmate.updateHelpmateStatus}(
    $helpmateId: String
    $status: String
    $jobId: String
    $updatedFrom: ReviewedBy!
  ){
    ${Helpmate.updateHelpmateStatus}(
      helpmateId: $helpmateId
      status: $status
      jobId: $jobId
      updatedFrom: $updatedFrom
    ){
      statusCode
      message
      data{
        helpmateId
      }
    }
  }
  `;

export const updateHelpmateProfileMutation = gql`
  mutation ${Helpmate.UpdateHelpmate}(
    $id: String!
    $name: String
    $lastName: String
    $email: String
    $mobileNumber: String
    $referedFrom: String
    $profilePic: String
    $bgPic: String
    $position: [positionInput]
    $yearOfExperience: String
    $skills: [String]
    $dayOfWeek: String
    $about: String
    $location: locationInput1
    $mile: Int
    
  ){
    ${Helpmate.UpdateHelpmate}(
      id: $id
      name: $name
      lastName: $lastName
      email: $email
      mobileNumber: $mobileNumber
      referedFrom: $referedFrom
      profilePic: $profilePic
      bgPic: $bgPic
      position: $position
      yearOfExperience: $yearOfExperience
      skills: $skills
      about: $about
      dayOfWeek: $dayOfWeek
      location: $location
      mile: $mile
    ){
      statusCode
      message
      data{
        id
        name
        lastName
        email
        mobileNumber
        referedFrom
        profilePic
        bgPic
        position{
          id
        }
        yearOfExperience
        skills
        dayOfWeek
        about
        location{
          id
          lat
          long
          city
          address
        }
        mile
      }
    }
  }
`;

export const getHelpmateNotificationQuery = gql`
  query ${Helpmate.GetAllHelpmateNotifi}(
    $helpmateId: String!
  ){
    ${Helpmate.GetAllHelpmateNotifi}(
      helpmateId: $helpmateId
    ){
      statusCode
      message
      data{
        id
        title
        description
        jobId
        helpmateId
        notificationType
        is_read
        createdAt
        updateAt
      }
    }
  }
`;

export const saveTimesheetMutation = gql`
  mutation ${Helpmate.SaveTimesheet}(
    $jobId: String!
    $helpmate: String!
    $id: String
    $startTime: String
    $endTime: String
  ){
    ${Helpmate.SaveTimesheet}(
      jobId: $jobId
      helpmate: $helpmate
      id: $id
      startTime: $startTime
      endTime: $endTime
    ){
      statusCode
      message
      data{
        id
        helpmate
        endTime
        createdAt
        hoursWorked
        jobId
        startTime
        status
        updateAt
      }
    }
  }
`;

export const onBoardHelpmateStatusMutation = gql`
  mutation ${Helpmate.OnBoardHelpmate}(
    $status: String
    $helpmateId: String!
  ){
    ${Helpmate.OnBoardHelpmate}(
      status: $status
      helpmateId: $helpmateId
    ){
      statusCode
      message
      data{
        helpmateId
      }
    }
  }
`;
