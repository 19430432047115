import { iState } from 'types';
import { businessReducer } from './business.reducer';
import { chatReducer } from './chat.reducer';
import { stripeReducer } from './stripe.reducer';
import { userReducer } from './user.reducer';

export const rootReducer = (state: iState, action): any => ({
  business: businessReducer(state.business, action),
  user: userReducer(state.user, action),
  chat: chatReducer(state.chat, action),
  stripe: stripeReducer(state.stripe, action),
});
