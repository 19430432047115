import { initialState } from 'store/initialState/initialState';
import { iStripeState } from 'types';
import { STRIPE, COMMON } from '../constants/index';

export const stripeReducer = (
  state: iStripeState,
  action
): iStripeState => {
  switch (action.type) {
    case STRIPE.setStripeCustomerId: {
      return { ...state, stripeCustomerId: action.payload };
    }
    case STRIPE.setClientSecretKey: {
      return { ...state, clientSecretKey: action.payload };
    }
    case STRIPE.setPaymentDetail: {
      return { ...state, paymentDetail: action.payload };
    }
    case COMMON.reset: {
      return initialState.stripe;
    }
    default:
      return state;
  }
};
