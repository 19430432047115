export enum RoleTypes {
  Admin = 'Admin',
  Helpmate = 'Helpmate',
  BusinessUser = 'BusinessUser',
  customer_success_manager = 'customer_success_manager',
  recruiter_or_hr = 'recruiter_or_hr',
  Business = 'Business',
}

export enum TempEnum {
  Value = 'value',
}

export enum PaymentStatus {
  Paid = 'paid',
  Refund = 'Refund',
}

export enum OtpFrom {
  BUSINESS = 'BUSINESS',
  User = 'User',
}

export enum EnvType {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}