import { gql } from 'apollo-boost';

export enum Issue_And_Review {
  GetAllHelpmateIssues = 'getAllHelpmateIssues',
  SaveHelpmateRating = 'saveHelpmateRating',
  SaveIssue = 'saveIssue',
  AdminIssuesList = 'adminIssuesList',
  GetIssueDetail = 'getIssueDetail',
  UpdateIssue = 'updateIssue',
  FilterAdminIssue = 'filterAdminIssue',
  HelpamteIssuesList = 'helpamteIssuesList',
}

export const getALlHelpmateIssuesQuery = gql`
  query ${Issue_And_Review.GetAllHelpmateIssues}($helpmateId: String! $sortBy: SortType){
    ${Issue_And_Review.GetAllHelpmateIssues}(helpmateId: $helpmateId sortBy: $sortBy){
      message
      statusCode
      data {
        id
        reporter_user_id
        reporter_email_id
        job{
          id
          jobName
          position{
            id
            name
          }
          startDate
          business{
            id
            logo
            name
          }
          location{
            id
            address
          }
        }
        problem
        status
        createdAt
        
      }
    }
  }
`;

export const saveHelpmateRatingMutation = gql`
  mutation ${Issue_And_Review.SaveHelpmateRating}(
    $jobId: String
    $helpmateId: String
    $rating: Float
    $title: String
    $description: String
    $reviewedBy: ReviewedBy
  ){
    ${Issue_And_Review.SaveHelpmateRating}(
      jobId: $jobId
      helpmateId: $helpmateId
      rating: $rating
      title: $title
      description: $description
      reviewedBy: $reviewedBy
    ){
      statusCode
      message
    }
  }
`;

export const saveIssueMutation = gql`
  mutation ${Issue_And_Review.SaveIssue}(
    $reporter_user_id: String!
    $reporter_email_id: String!
    $job: String!
    $problem: String!
    $businessId: String
    $createdBy: issueFrom!
    $forAdmin: Boolean
  ){
    ${Issue_And_Review.SaveIssue}(
      reporter_user_id: $reporter_user_id
      reporter_email_id: $reporter_email_id
      job: $job
      problem: $problem
      businessId: $businessId
      createdBy: $createdBy
      forAdmin: $forAdmin
    ){
      statusCode
      message
      data{
        id
      reporter_user_id
      reporter_email_id
      problem
      status
      createdAt
      updateAt
      deletedAt
      }
    }
  }
`;

export const adminIssuesListQuery = gql`
  query ${Issue_And_Review.AdminIssuesList}(
    $createdBy: issueFrom
    $searchKey: String! 
    $skip: Int
    $limit: Int
    ){
    ${Issue_And_Review.AdminIssuesList}(
      createdBy: $createdBy 
      searchKey: $searchKey 
      limit: $limit 
      skip: $skip
      ){
      statusCode
      message
      data{
       count
       issurList{
                  id
                  reporter_user_id
                  reporter_email_id
                  business{
                    id
                    name
                  }
                  job{
                    id
                    jobName
                    business{
                      id
                      name
                    }
                  }
                  helpmateName
                  problem
                  status
                  forAdmin
                  updateAt
                  deletedAt
                  createdAt
                  createdBy
                  user {
                    name
                  }
                }
            }
          }
        }
`;

export const getIssueDetailQuery = gql`
  query ${Issue_And_Review.GetIssueDetail}(
    $issueId: String
    ){
    ${Issue_And_Review.GetIssueDetail}(
      issueId: $issueId 
      ){
        statusCode
        message
        data{
              id
              reporter_user_id
              reporter_email_id
              job{
                  id
                  jobName
                  }
              
              problem
              status
              createdBy
              forAdmin
              updateAt
              createdAt
              user {
                name
              }
            }
          }
        }
`;

export const updateIssueMutation = gql`
    mutation ${Issue_And_Review.UpdateIssue}(
      $id: String
      $status: String!
      ){
        ${Issue_And_Review.UpdateIssue}(
          id: $id
          status: $status
        ){
          message
          statusCode
          data{
            id
            reporter_user_id
            reporter_email_id
            status
          }
        }    
      }
`;
export const filterAdminIssueQuery = gql`
  query ${Issue_And_Review.FilterAdminIssue}(
    $createdBy: issueFrom 
    $filterType: String
    $filter: String
    $skip: Int
    $limit: Int
    ){
    ${Issue_And_Review.FilterAdminIssue}(
      createdBy: $createdBy
      filterType: $filterType
      filter: $filter
      skip: $skip
      limit: $limit
      ){
      statusCode
      message
      data{
        count
        issurList{
          id
          reporter_user_id
          reporter_email_id
          business{
            id
            name
          }
          job{
            id
            jobName
            business{
              id
              name
            }
          }
          helpmateName
          problem
          status
          forAdmin
          updateAt
          deletedAt
          createdAt
          createdBy
          user {
                name
                }
        }
      }
  
    }
  }
`;

export const helpamteIssuesListQuery = gql`
  query ${Issue_And_Review.HelpamteIssuesList}(
    $searchKey: String!
    $skip: Int
    $limit: Int
    ){
    ${Issue_And_Review.HelpamteIssuesList}(
      searchKey: $searchKey
      skip: $skip
      limit: $limit
      ){
      statusCode
      message
      data{
        count
        issurList{
          id       
          reporter_user_id
          reporter_email_id
          status
          helpmateName
          problem
          createdAt
        }  
      }
  
    }
  }
`;
