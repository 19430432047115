import CustomButton from 'components/CustomButton/CustomButton';
import { errorIcon } from 'constants/icons';
import { useNavigate } from 'react-router-dom';
import styles from './goBack.module.scss';

const GoBack = () => {
  let navigate = useNavigate();

  return (
    <div className={styles.errorContainer}>
        <img alt="Under development" src={errorIcon} />
        <p className={styles.errorMessage}>
          Sorry, It seems like you don't have the required permissions to access this page.
        </p>
        <CustomButton
          type="submit"
          variant="contained"
          onClick={() => navigate(-1)}
          className={styles.goBackBtn}
        >
          Go Back
        </CustomButton>
    </div>
  );
};

export default GoBack;
