import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Grid,
  InputAdornment,
} from '@mui/material';
import FormTextField from 'components/FormTextField/FormTextField';
import BaseAuthentication from 'components/Helpmates/Authentication/BaseAuthentication';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/paths';
// import Lock from 'icons/Lock';
import styles from './helpmate.module.scss';
import { setNewPasswordApi } from 'apis';
import { toaster } from 'helpers';
import CustomButton from 'components/CustomButton/CustomButton';
import usePasswordToggle from 'hooks/usePasswordToggle';
import { useStore } from 'store';
import { iState } from 'types';
import { RoleTypes } from 'enums';
interface iProps {
  className?: string;
}

const HelpmateResetPassword = (props: iProps): JSX.Element => {
  const { className } = props;
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [state]: [iState, any] = useStore();
  const { helpmateEmailId } = state.user;

  const initialValues = {
    newPassword: newPassword,
    confirmPassword: confirmPassword,
  };
  const [PasswordInputType1, Icon] = usePasswordToggle();
  const [PasswordInputType, confirmIcon] = usePasswordToggle();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Fields cannot be empty').min(8, "Your password must contain uppercase, lowercase, special character, numbers and must be 8+ characters longer").max(15)
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Your password must contain uppercase, lowercase, numbers and must be 8+ characters longer.'
      ),
    confirmPassword: Yup.string()
      .required('Fields cannot be empty')
      .oneOf([Yup.ref('newPassword')], 'Password entries do not match'),
  });
  const handleSetPassword = useCallback(async () => {
    setLoading(true);
    const response = await setNewPasswordApi(
      {
        password: newPassword.trim(),
        email: helpmateEmailId,
        type: RoleTypes.Helpmate
      },);
    if (response) {
      navigate(ROUTES.login);
      setLoading(false);
      toaster.success('Password reset successfully');
    }
    if (!response) {
      setLoading(false);
    }
  }
    , [newPassword, helpmateEmailId, navigate]);

  return (
    <div>
      <BaseAuthentication />
      <Grid
        container
        spacing={2}
        direction="column"
        alignContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={`${styles.resetPassword} ${className}`}
        >
          <div>
            <p className={styles.newPassword}>Set a password</p>
            <p className={styles.verificationText}>
              Your password must contain uppercase, lowercase, numbers and must be 8+ characters longer.
            </p>
            <div className={styles.formContainer}>
              {/* <ResendOTP onResendClick={() => console.log('Resend clicked')} /> */}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                sx={{ height: '100%' }}
                onSubmit={handleSetPassword}
              >
                {({ errors, handleBlur, handleSubmit, touched }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormTextField
                      margin='normal'
                      variant="outlined"
                      label="Password"
                      placeholder='Password'
                      className={styles.passInput}
                      fullWidth
                      type={`${PasswordInputType1}`}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      onBlur={handleBlur}
                      name="newPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">

                            <section>

                              {/* <Lock/> */}
                              <span style={{ paddingLeft: "5px" }}>
                                {Icon}
                              </span>
                            </section>

                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormTextField
                      margin='normal'
                      value={confirmPassword}
                      variant="outlined"

                      label='Confirm Password'
                      placeholder="Confirm Password"
                      fullWidth
                      type={`${PasswordInputType}`}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      name="confirmPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <section>
                              {/* <Lock /> */}
                              <span style={{ paddingLeft: "5px" }}>
                                {confirmIcon}
                              </span>
                            </section>

                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div className={styles.btnContainer}>
                      <CustomButton
                        color="primary"
                        fullWidth
                        variant="contained"
                        className={styles.submitButton}
                        type="submit"
                      >
                        {loading === true ? 'Please wait' : 'SAVE PASSWORD'}
                      </CustomButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpmateResetPassword;
