export const COMMON = {
  reset: 'reset',
};

export const BUSINESS = {
  getAllIndustries: 'getAllIndustries',
  getAllBusiness: 'getAllBusiness',
  setCompanyLogo: 'setCompanyLogo',
  setBusinessId: 'setBusinessId',
  setSlots: 'setSlots',
  setBusinessUserId: 'setBusinessUserId',
  setAdminBusinessId: 'setAdminBusinessId',
  setFilterIds: 'setFilterIds',
  clearFilterIds: 'clearFilterIds',
  setJobDetailsId: 'setJobDetailsId',
  setAllSkills: 'setAllSkills',
  setJobSkills: 'setJobSkills',
  setApplicants: 'setApplicants',
  invokeUpdateBusinessDetails: 'invokeUpdateBusinessDetails',
  setRole: "setRole",
};

export const CHAT = {
  setToPersonIds: 'setToPersonIds',
  invokeConversation: 'invokeConversation',
  invokeInboxList: 'invokeInboxList',
};

export const USER = {
  setProfilePicture: 'setProfilePicture',
  setAllPositions: 'setAllPositions',
  setPersonalId: 'setPersonalId',
  setProfilPic: 'setProfilPic',
  setLoginData: 'setLoginData',
  setEmailId: 'setEmailId',
  setHelpmateEmailId: 'setHelpmateEmailId',
  setHelpmateId: 'setHelpmateId',
  toggleShowTimeBox: 'toggleShowTimeBox',
  setHelpmateBgPic: 'setHelpmateBgPic',
  setHelpmateIssueId: 'setHelpmateIssueId',
  setJobStatus: 'setJobStatus',
  setManagerId: 'setManagerId',
  setJobCount: 'setJobCount',
  setHelpmateData: 'setHelpmateData',
  setHelpmateCity: 'setHelpmateCity',
  setLoggedInUserName: 'setLoggedInUserName',
  setHelpmateSkills: 'setHelpmateSkills',
  setEnvName: 'setEnvName',
};

export const STRIPE = {
  setStripeCustomerId: 'setStripeCustomerId',
  setClientSecretKey: 'setClientSecretKey',
  setPaymentDetail: 'setPaymentDetail',
}
