import { Query } from 'config';
import {
  GetAllHelpmateJobsArgs,
  iGetAllHelpmateJobsResponse,
  iGetAllHelpmateJobsData,
  iGraphqlResponse,
  iSearchHelpmateJobNewArgs,
  iSearchHelpmateJobNewData,
  iSearchHelpmateJobNewResponse,
} from 'types';
import { GetAllHelpmateJobsQuery, searchHelpmateJobNewQuery } from '../graphql';

export const getAllHelpmateJobs = async (
  args: GetAllHelpmateJobsArgs,
  isLoading
): Promise<Array<iGetAllHelpmateJobsData> | null> => {
  try {
    console.log('getAllHelpmateJobs');
    isLoading(true);
    const response = (
      await Query(GetAllHelpmateJobsQuery, args)
    ) as iGraphqlResponse<iGetAllHelpmateJobsResponse>;
    isLoading(false);
    return response.data.getAllHelpmateJobs.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    return null;
  }
};

export const searchHelpmateJobNewApi = async (
  args: iSearchHelpmateJobNewArgs,
  isLoading
): Promise<iSearchHelpmateJobNewData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(searchHelpmateJobNewQuery, args)
    ) as iGraphqlResponse<iSearchHelpmateJobNewResponse>);
    isLoading(false);
    return response.data.searchHelpmateJobNew.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};
