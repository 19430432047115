import { useState, useEffect } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import ErroImage from 'assets/ErrorImage.png';
import styles from './offline.module.scss';


function OfflineDetector({ children }): JSX.Element {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  }, []);

  return isOnline ? (
    children
  ) : (
    <div className={styles.mainContainer}>
      <div className={styles.errorContainer}>
        <img src={ErroImage} alt="" />
        <span className={styles.errorMessage}>
          Network connection lost. Please check your connection and try again.          </span>
        <CustomButton
          className={styles.gotoScreenBtn}
          onClick={() => window.location.reload()}
          variant='contained'
        >Refresh
        </CustomButton>
      </div>
    </div>
  );
}

export default OfflineDetector;
