import styles from './loader.module.scss';

interface iProps {
  className?: string;
}

const Loader = (props: iProps): JSX.Element => {
  const { className } = props
  return (
    <div className={`${className} ${styles.loadContainer}`}>
      <div className={styles.loader} />
    </div>
  );
};

Loader.defaultProps = {
  className: '',
}

export default Loader;
