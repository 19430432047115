import { iUserState } from 'types';
import { USER, COMMON } from '../constants/index';
import { initialState } from '../initialState/initialState';

export const userReducer = (state: iUserState, action): iUserState => {
  switch (action.type) {
    case USER.setProfilePicture: {
      return { ...state, profilePicture: action.payload };
    }
    case USER.setAllPositions: {
      return { ...state, allPositions: action.payload };
    }
    case USER.setPersonalId: {
      return { ...state, personalId: action.payload };
    }
    case USER.setHelpmateCity: {
      return { ...state, helpmateCity: action.payload };
    }
    case USER.setProfilPic: {
      return { ...state, profilPic: action.payload };
    }
    case USER.setHelpmateSkills: {
      return { ...state, helpmateSkills: action.payload };
    }
    case USER.setHelpmateBgPic: {
      return { ...state, helpmateBgPic: action.payload };
    }
    case USER.setEmailId: {
      return { ...state, emailId: action.payload };
    }
    case USER.setHelpmateEmailId: {
      return { ...state, helpmateEmailId: action.payload };
    }
    case USER.setHelpmateId: {
      return { ...state, helpmateId: action.payload };
    }
    case USER.setLoginData: {
      return { ...state, isLoggedIn: true, loggedIn: action.payload };
    }
    case USER.toggleShowTimeBox: {
      return { ...state, showTimeBoxPopup: !state.showTimeBoxPopup };
    }
    case USER.setHelpmateIssueId: {
      return { ...state, helpmateIssueId: action.payload };
    }
    case USER.setJobStatus: {
      return { ...state, jobStatus: action.payload };
    }
    case USER.setManagerId: {
      return { ...state, managerId: action.payload };
    }
    case USER.setJobCount: {
      return { ...state, jobCount: action.payload };
    }
    case USER.setHelpmateData: {
      return { ...state, helpmateData: action.payload };
    }
    case USER.setLoggedInUserName: {
      return { ...state, loggedInUserName: action.payload };
    }
    case USER.setEnvName: {
      return { ...state, envName: action.payload };
    }
    case COMMON.reset: {
      return initialState.user;
    }
    default:
      return state;
  }
};
