import { Grid, Card, InputAdornment } from '@mui/material';
import FaveoMainLogo from 'icons/FaveoMainLogo.png';
import { heroHeaderpic, ROUTES } from 'constants/index';
import CustomButton from 'components/CustomButton/CustomButton';
import Lock from 'icons/Lock';
import FormTextField from 'components/FormTextField/FormTextField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './passwordSetup.module.scss';
import { setNewPasswordApi } from 'apis';
import { toaster } from 'helpers';
import usePasswordToggle from 'hooks/usePasswordToggle';
import { useStore } from 'store';
import { iState } from 'types';

const AdminResetPassword = (): JSX.Element => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state]: [iState, any] = useStore();
  const { emailId } = state.user;

  const initialValues = {
    newPassword: newPassword,
    confirmPassword: confirmPassword,
  };
  const [PasswordInputType1, Icon] = usePasswordToggle();
  const [PasswordInputType, confirmIcon] = usePasswordToggle();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8)
      .max(15)
      .required('Fields cannot be empty')
      .matches(
        /^.*(?=.{8,})((?=.*[!?/@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Your password must contain uppercase, lowercase, special character, numbers and must be 8+ characters longer"
      ),
    confirmPassword: Yup.string()
      .required('Fields cannot be empty')
      .oneOf([Yup.ref('newPassword')], 'Password entries do not match'),
  });
  const handleSetPassword = useCallback(async () => {
    setLoading(true);
    const response = await setNewPasswordApi({
      password: newPassword.trim(),
      email: emailId,
    });
    if (response) {
      navigate(ROUTES.login);
      setLoading(false);
      toaster.success('Password reset successfully');
    }
    if (!response) {
      setLoading(false);
    }
  }, [newPassword, emailId, navigate]);

  return (
    <div>
      <Grid container spacing={2} className={styles.passwordContiner}>
        <Grid item xs={8} sm={7} md={7}>
          <div className={styles.passwordBox}>
            <Card className={styles.passwordCard}>
              <img src={FaveoMainLogo} alt="faveo logo" />
              <p className={styles.passwordTitle}>
                Thank you for Verifying your email
              </p>
              <p className={styles.passwordSubTitle}>
                Please set your password
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                sx={{ height: '100%' }}
                onSubmit={handleSetPassword}
              >
                {({ errors, handleBlur, handleSubmit, touched }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormTextField
                      variant="outlined"
                      placeholder="Password"
                      className={styles.passInput}
                      fullWidth
                      type={`${PasswordInputType1}`}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      onBlur={handleBlur}
                      name="newPassword"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className={styles.pswToggle}>{Icon}</span>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormTextField
                      value={confirmPassword}
                      variant="outlined"
                      placeholder="Confirm Password"
                      fullWidth
                      type={`${PasswordInputType}`}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      name="confirmPassword"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className={styles.pswToggle}>
                              {confirmIcon}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div>
                      <CustomButton
                        color="primary"
                        fullWidth
                        variant="contained"
                        className={styles.submitButton}
                        type="submit"
                      >
                        {loading === true ? 'Please wait' : 'go to log in'}
                      </CustomButton>
                    </div>
                  </form>
                )}
              </Formik>

            </Card>
          </div>
        </Grid>
        <Grid item xs={4} sm={7} md={5}>
          <Card className={styles.heroHeaderCard}>
            <img src={heroHeaderpic} alt="heroPicture" />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminResetPassword;
