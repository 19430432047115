import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import {
  iGraphqlResponse,
  iListPaymentMethodArgs,
  iListPaymentMethodData,
  iListPaymentMethodResponse,
  iPaymentDetailData,
  iPaymentDetailResponse,
  iPaymentDetailResponseArgs,
  ipaymentIntentwithSaveCardArgs,
  iPaymentIntentwithSaveCardData,
  ipaymentIntentwithSaveCardResponse,
  iPaymentListArgs,
  iPaymentListData,
  iPaymentListResponse,
  iSetupPaymentIntentArgs,
  iSetupPaymentIntentData,
  iSetupPaymentIntentResponse,
  iStripCustomerDetailArgs,
  iStripCustomerDetailData,
  iStripCustomerDetailResponse,
  iUpdateDefaultPaymentMethodArgs,
  iUpdateDefaultPaymentMethodData,
  iUpdateDefaultPaymentMethodResponse,
} from 'types';
import {
  getListPaymentMethodQuery,
  getSetupPaymentIntentQuery,
  getStripCustomerDetailQuery,
  paymentDetailQuery,
  paymentIntentwithSaveCardMutation,
  paymentListQuery,
  updateDefaultPaymentMethodMutation,
} from '../graphql';

export const getStripCustomerDetail = async (
  args: iStripCustomerDetailArgs,
  isLoading
): Promise<iStripCustomerDetailData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getStripCustomerDetailQuery, args)
    ) as iGraphqlResponse<iStripCustomerDetailResponse>);
    isLoading(false);
    return response.data.stripCustomerDetail.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getSetupPaymentIntent = async (
  args: iSetupPaymentIntentArgs,
  isLoading
): Promise<iSetupPaymentIntentData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getSetupPaymentIntentQuery, args)
    ) as iGraphqlResponse<iSetupPaymentIntentResponse>);
    isLoading(false);
    return response.data.setupPaymentIntent.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getListPaymentMethod = async (
  args: iListPaymentMethodArgs,
  isLoading
): Promise<iListPaymentMethodData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getListPaymentMethodQuery, args)
    ) as iGraphqlResponse<iListPaymentMethodResponse>);
    isLoading(false);
    return response.data.listPaymentMethod.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    // toaster.error(error.message);
    return null;
  }
};

export const updateDefaultPaymentMethodApi = async (
  args: iUpdateDefaultPaymentMethodArgs,
  isLoading
): Promise<iUpdateDefaultPaymentMethodData | null> => {
  try {
    isLoading(true);
    const response = await (await Mutation(updateDefaultPaymentMethodMutation, args) as iGraphqlResponse<iUpdateDefaultPaymentMethodResponse>);
    isLoading(false);
    return response.data.updateDefaultPaymentMethod.data;
  } catch (error: any) {
    isLoading(false);
    // captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const paymentIntentwithSaveCardApi = async (
  args: ipaymentIntentwithSaveCardArgs,
  isLoading
): Promise<iPaymentIntentwithSaveCardData | null> => {
  try {
    isLoading(true);
    const response = await (await Mutation(paymentIntentwithSaveCardMutation, args) as iGraphqlResponse<ipaymentIntentwithSaveCardResponse>);
    isLoading(false);
    return response.data.paymentIntentwithSaveCard.data;
  } catch (error: any) {
    isLoading(false);
    // captureException(error);
    console.log(error.message);
    return null;
  }
};

export const paymentListApi = async (
  args: iPaymentListArgs,
  isLoading
): Promise<iPaymentListData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(paymentListQuery, args)
    ) as iGraphqlResponse<iPaymentListResponse>);
    isLoading(false);
    return response.data.paymentList.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    // toaster.error(error.message);
    return null;
  }
};

export const paymentDetailApi = async (
  args: iPaymentDetailResponseArgs,
  isLoading
): Promise<iPaymentDetailData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(paymentDetailQuery, args)
    ) as iGraphqlResponse<iPaymentDetailResponse>);
    isLoading(false);
    return response.data.paymentDetail.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};
