import { gql } from 'apollo-boost';


export enum Stripe {
  StripCustomerDetail = 'stripCustomerDetail',
  SetupPaymentIntent = 'setupPaymentIntent',
  ListPaymentMethod = 'listPaymentMethod',
  SavePaymentMethodId = 'savePaymentMethodId',
  UpdateDefaultPaymentMethod = 'updateDefaultPaymentMethod',
  DeleteCard = 'deleteCard',
  PaymentIntentwithSaveCard = 'paymentIntentwithSaveCard',
  PaymentList = 'paymentList',
  ReceivePayment = 'receivePayment',
  PaymentDetail = 'paymentDetail',
  RefundPayment = 'refundPayment',
  DeleteJob = "deleteJob"
}

export const getStripCustomerDetailQuery = gql`
        query ${Stripe.StripCustomerDetail}($userId: String!){
            ${Stripe.StripCustomerDetail}(userId: $userId){
                message
                statusCode
                data{
                    id
                    name
                    email
                    balance
                    address
                    defaultPaymentMethodId
                    stripeCustomerId
                }
            }
        }
`;

export const getSetupPaymentIntentQuery = gql`
        query ${Stripe.SetupPaymentIntent}($sCustomerId: String! $userType: ReviewedBy!){
            ${Stripe.SetupPaymentIntent}(sCustomerId: $sCustomerId userType: $userType){
                message
                statusCode
                data{
                    clientSecret
                }
            }
        }

`;

export const getListPaymentMethodQuery = gql`
        query ${Stripe.ListPaymentMethod}($userId: String!){
            ${Stripe.ListPaymentMethod}(userId: $userId){
                statusCode
                message
                name
                data{
                  card{
                    id
                    object
                    type
                    card{
                      brand
                      country
                      exp_month
                      exp_year
                      fingerprint
                      last4
                    }
                    isDefaultPaymentMethod
                  }
                  bank{
                    id
                    billing_details{
                      email
                      name
                    }
                    us_bank_account{
                      fingerprint
                      last4
                      bank_name
                      account_type
                      account_holder_type
                    }
                    isDefaultPaymentMethod
                  }
                 }
            }
        }

`;

export const SavePaymentMethodIdMutation = gql`
    mutation ${Stripe.SavePaymentMethodId}(
      $userId: String!
      $setupIntentId: String!
      ){
        ${Stripe.SavePaymentMethodId}(
            userId: $userId
            setupIntentId: $setupIntentId
            
          ){
            message
            statusCode
        }
    }
`;

export const updateDefaultPaymentMethodMutation = gql`
    mutation ${Stripe.UpdateDefaultPaymentMethod}($paymentMethodId: String!){
        ${Stripe.UpdateDefaultPaymentMethod}(paymentMethodId: $paymentMethodId){
            statusCode
            message
            name
            data{
                id
                customer{
                  id
                  userId
                  email
                  name
                  balance
                  address
                  defaultPaymentMethodId
                  stripeCustomerId
                  userType
                }
                paymentMethodStripeId
                type
              }
        }
    }    
`;

export const deleteCardMutation = gql`
    mutation ${Stripe.DeleteCard}(
      $sPaymentMethodId: String!
      ){
        ${Stripe.DeleteCard}(
            sPaymentMethodId: $sPaymentMethodId
          ){
            message
            statusCode
        }
    }
`;

export const paymentIntentwithSaveCardMutation = gql`
mutation ${Stripe.PaymentIntentwithSaveCard}(
  $userId: String!
  $amount: Float!
  ){
    ${Stripe.PaymentIntentwithSaveCard}(
      userId: $userId
      amount: $amount
      ){
        message
        statusCode
        data {
          clientSecret
          paymentMethodId
        }
    }
}
`;

export const paymentListQuery = gql`
    query ${Stripe.PaymentList}(
      $businessId: String
      $helpmateId: String
      $skip: Int
      $limit: Int
      ){
        ${Stripe.PaymentList}(
          businessId: $businessId
          helpmateId: $helpmateId
          skip: $skip
          limit: $limit
          ){
            statusCode
            message
            name
            data{
              paymentListCount
              paymentList{
                id
              userId
              jobId
              jobName
              businessId
              status
              amount
              transactionId
              paymentFrom
              createdAt
              updateAt
              }
            }
          }
      }
`;

export const receivePaymentMutaion = gql`
    mutation ${Stripe.ReceivePayment}(
      $userId: String!
      $amount: String!
      $businessId: String!
      $jobName: String!
      $jobId: String!
      $transactionId: String!
      ){
        ${Stripe.ReceivePayment}(
            userId: $userId
            amount: $amount
            businessId: $businessId
            jobName: $jobName
            jobId: $jobId
            transactionId: $transactionId
          ){
            message
            statusCode
        }
    }
`;

export const paymentDetailQuery = gql`
    query ${Stripe.PaymentDetail}(
      $transactionId: String
      $paymentId: String!
      $userId: String!
    ){
      ${Stripe.PaymentDetail}(
        transactionId: $transactionId
        paymentId: $paymentId
        userId: $userId

      ){
        statusCode
        message
        data{
          id
          userId
          job{
            id
            jobName
            upCharge
            totalErning
            totalLaborHours
            subTotal
            stripeFee
            total
            business{
              id
              name
              location{
                id
                address
              }
            }
            noOfWorkers
            payment
            upCharge
            totalHours
            paymentDetail{
              id
              status
              amount
              transactionId
              paymentFrom
              createdAt
              updateAt
            }
          }
          jobName
          businessId
          status
          amount
          transactionId
          paymentFrom
          createdAt
          updateAt
        }
      }
    }
`;

export const RefundPaymentMutation = gql`
    mutation ${Stripe.RefundPayment}(
      $jobId: String!
      ){
        ${Stripe.RefundPayment}(
            jobId: $jobId
          ){
            message
            statusCode
        }
    }
`;
export const DeleteJobMutation = gql`
mutation ${Stripe.DeleteJob}(
  $jobId: String!
  ){
    ${Stripe.DeleteJob}(
      jobId: $jobId
      ){
        message
        statusCode
    }
}
`;