import { gql } from 'apollo-boost';

export enum JobList {
  GetJobDetails = 'getJobDetails',
  iGetAllHelpmateJobs = 'getAllHelpmateJobs',
  CreateJob = 'createJob',
  NearByJobs = 'nearByJobs',
  searchHelpmateJobNew = 'searchHelpmateJobNew',
}

export const getJobDetailsQuery = gql`
query ${JobList.GetJobDetails}($id: String!){
    ${JobList.GetJobDetails}(id: $id) {
        statusCode
        message
        data{
          applicants
          status
            id
            jobName,
            reportingTo,
            sectionName,
            payment,
            totalErning,
            position {
                      id
                      name
                      createdAt
                      },
            noOfWorkers,
            yearOfExperience,
            description,
            skills,
            equipment,
            covid19,
            slot,
            startDate,
            endDate,
            totalLaborHours,
            dayOfWeek,
            pointContact,
            userId,
            siteInstruction
            stripeFee
            subTotal
            upCharge
            total
            business{
                      id
                      name
                      logo
                    },
            location{
                      lat
                      long
                      id
                      address
                    }
            paymentDetail{
                          id
                          userId
                          jobId
                          jobName
                          businessId
                          amount
                          transactionId
                          paymentFrom
                          status
                        }
                      }
                    }
                  }
                `;

export const GetAllHelpmateJobsQuery = gql`
query ${JobList.iGetAllHelpmateJobs}($id: String){
    ${JobList.iGetAllHelpmateJobs}(id: $id) {
        statusCode
        message
        data{
            status
            id
            jobName,
        reportingTo,
        sectionName,
        payment,
        position,
        noOfWorkers,
        yearOfExperience,
        description,
        skills,
        equipment,
        covid19,
        startDate,
        endDate,
        totalLaborHours,
        dayOfWeek,
        pointContact,
        userId,
        businessId,
          location{
            lat
            long
            id
            address
          }
        }
    }
}
`;

export const createJobMutation = gql`
mutation ${JobList.CreateJob}(
  $jobName: String!
  $reportingTo: String!
  $sectionName: String!
  $payment: Float!
  $position: String!
  $noOfWorkers: Float!
  $yearOfExperience: Float!
  $description: String!
  $skills: [String]!
  $equipment: String
  $covid19: String!
  $startDate: String!
  $endDate: String!
  $startTime: String!
  $totalLaborHours: String!
  $dayOfWeek: String!
  $pointContact: String!
  $userId: String!
  $business: String!
  $location: locationInput
  $siteInstruction: String!
  $subTotal: Float
  $stripeFee: Float
  $upCharge: Float
  $total: Float
  ){
    ${JobList.CreateJob}(
      jobName: $jobName
      reportingTo: $reportingTo
      sectionName: $sectionName
      payment: $payment
      position: $position
      noOfWorkers: $noOfWorkers
      yearOfExperience: $yearOfExperience
      description: $description
      skills: $skills
      equipment: $equipment
      covid19: $covid19
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      totalLaborHours: $totalLaborHours
      dayOfWeek: $dayOfWeek
      pointContact: $pointContact
      userId: $userId
      business: $business
      location: $location
      siteInstruction: $siteInstruction
      subTotal: $subTotal
      stripeFee: $stripeFee
      upCharge: $upCharge
      total: $total
      ){
        message
        statusCode
        data {
          id
          paymentDetail{
            id
            userId
            jobId
            jobName
            businessId
            status
            amount
            transactionId
            paymentFrom
          }
        }
    }
}
`;

export const nearByJobsQuery = gql`
  query ${JobList.NearByJobs}($city: String! $skip: Int $limit: Int){
    ${JobList.NearByJobs}(city: $city skip: $skip limit: $limit){
      statusCode
      message
      data{
        count
        jobList{
          id
          jobName
          createdAt
          payment
          position{
            id
            name
          }
          business{
            id
            name
            logo
          }
          location{
            id
            city
            address
          }
          
        }
      }
    }
  }
`;

export const searchHelpmateJobNewQuery = gql`
      query ${JobList.searchHelpmateJobNew}(
        $searchKey: String
        $helpmateId: String
        $sortBy: String
        $status: String
      ){
        ${JobList.searchHelpmateJobNew}(
          searchKey: $searchKey
          helpmateId: $helpmateId
          sortBy: $sortBy
          status: $status
        ){
          statusCode
          message
          data{
            id
            jobName
            reportingTo
            sectionName
            payment
            position{
              id
              name
            }
            noOfWorkers
            yearOfExperience
            description
            skills
            equipment
            covid19
            startDate
            endDate
            startTime
            totalLaborHours
            dayOfWeek
            siteInstruction
            pointContact
            userId
            business{
              id
              name
              logo
            }
            status
            upCharge
            personName
            applicants
            location{
              id
              lat
              long
              city
              address
            }
            rating
            totalHours
            jobImage
            paymentDetail{
              id
              userId
              jobId
              status
              amount
              businessId
              jobName
              transactionId
              paymentFrom
            }
          }
        }
      }
`;
