import { CHAT } from 'store/constants';
import { iChatIds } from 'types';

export const chatReducer = (state: iChatIds, action): iChatIds => {
  switch (action.type) {
    case CHAT.setToPersonIds: {
      return { ...state, ...action.payload };
    }
    case CHAT.invokeConversation: {
      return { ...state, updateConversation: !state.updateConversation };
    }
    case CHAT.invokeInboxList: {
      return { ...state, updateInboxList: !state.updateInboxList };
    }
    default:
      return state;
  }
};
