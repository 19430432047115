import { gql } from 'apollo-boost';

export enum Admin {
  DemandedSkills = 'demandedSkills',
  TotalHoursAnalytics = 'totalHoursAnalytics',
  ActiveAnalytics = 'activeAnalytics',
  BusinessAnalytics = 'businessAnalytics',
  BusinessRevenu = 'businessRevenu',
  TotalBookedHours = 'totalBookedHours',
  SearchManager = 'searchManager',
  AddManager = 'addManager',
  GetManagerDetails = 'getManagerDetails',
  RemoveMannager = 'removeMannager',
  GetFilteredManagers = 'getFilteredManagers',
  GetClientIndustries = 'getClientIndustries',
}

export const getDemandedSkillsQuery = gql`
  query ${Admin.DemandedSkills}{
    ${Admin.DemandedSkills} {
      message
      statusCode
      data
    }
  } 
`;

export const getTotalHoursAnalyticsQuery = gql`
  query ${Admin.TotalHoursAnalytics}(
    $businessId: String 
  ){
    ${Admin.TotalHoursAnalytics}(
      businessId: $businessId
    ){
      message
      statusCode
      data {
        totalhourspending{
          totalhourspending
          percentage
        }
        totoalrequesthours{
          totoalrequesthours
          percentage
        }
        totalhourscompleted{
          totalhourscompleted
          percentage
        }
      }
    }
  }
`;

export const getActiveAnalyticsQuery = gql`
  query ${Admin.ActiveAnalytics}{
    ${Admin.ActiveAnalytics}{
      message
      statusCode
      data {
        activeBusiness{
          activebusiness60
          percentage
        }
        activejob{
          activejob
          percentage
        }
        activeHelpmat{
          activehelpmate
          percentage
        }
        fullfillmentData{
          month
          totWorkersReq
          totWorkers
        }
        revenueGrowthData{
          month
          totalMonthyPayment
        }
      }
    }
  }
`;

export const getBusinessAnalyticsQuery = gql`
    query ${Admin.BusinessAnalytics}{
      ${Admin.BusinessAnalytics}{
        message
        statusCode
        data{
          pendingbusiness{
            totalPendingbusiness
            percentage
          }
          accepetedbusiness{
            totalAcceptedbusiness
            percentage
          }
    
        }
      }
    }
`;

export const getBusinessRevenueQuery = gql`
    query ${Admin.BusinessRevenu}($businessId: String!){
      ${Admin.BusinessRevenu}(businessId: $businessId){
        message
        statusCode
        data{
          month
          totalMonthyPayment
        }
      }
    }
`;

export const getTotalBookedHoursQuery = gql`
    query ${Admin.TotalBookedHours}($businessId: String!){
      ${Admin.TotalBookedHours}(businessId: $businessId){
        message
        statusCode
        data{
          totalHoursBooked
          percentage
        }
      }
    }
`;

export const searchManagerQuery = gql`
    query ${Admin.SearchManager}($searchKey: String, $skip: Int, $limit: Int){
      ${Admin.SearchManager}(searchKey: $searchKey, skip: $skip, limit: $limit){
        statusCode
        message
        data{
          count
          managerList{
            id
            name
            lastName
            email
            role
            activeStatus
          }
        }
      }
    }
`;

export const addManagerMutation = gql`
  mutation ${Admin.AddManager}(
    $id: String
    $name: String
    $lastName: String
    $email: String
    $role: String
    $activeStatus: String
    $mobileNumber: String
    $update: Boolean!
    $password: String
    $profilePic: String
    $settings: settingInput
  ){
    ${Admin.AddManager}(
      id: $id
      name: $name
      lastName: $lastName
      email: $email
      role: $role
      activeStatus: $activeStatus
      mobileNumber: $mobileNumber
      update: $update
      password: $password
      profilePic: $profilePic
      settings: $settings
    ){
      statusCode
      message
      data{
        id
        name
        lastName
        mobileNumber
        email
        role
        profilePic
        settings{
          id
          notificationEmail
          notificationPush
          textMessage
          pohoneCall
          chatNotificationEmail
          chatNotificationPush
        }
      }
    }
  }
`;

export const getManagerDetailsQuery = gql`
    query ${Admin.GetManagerDetails}($userId: String){
      ${Admin.GetManagerDetails}(userId: $userId){
        statusCode
        message
        data{
              id
              name
              lastName
              mobileNumber
              email
              role
              activeStatus
              profilePic
              position{
                id
                name
              }
              settings{
                id
                notificationEmail
                notificationPush
                textMessage
                pohoneCall
                chatNotificationEmail
              }
            }
          }
        }
`;

export const removeMannagerMutation = gql`
      mutation ${Admin.RemoveMannager}($userId: String!){
        ${Admin.RemoveMannager}(userId: $userId){
          statusCode
          message
        }
      }
`;

export const getFilteredManagersQuery = gql`
        query ${Admin.GetFilteredManagers}($filterType: String $filter: String $skip: Int, $limit: Int){
              ${Admin.GetFilteredManagers}(filterType: $filterType filter: $filter skip: $skip limit: $limit){
                statusCode
                message
                data{
                  count
                  managerList{
                    id
                    name
                    lastName
                    mobileNumber
                    role
                    activeStatus
                  }
                }
              }
        }
`;

export const getClientIndustriesQuery = gql`
    query ${Admin.GetClientIndustries}{
      ${Admin.GetClientIndustries}{
        statusCode
        message
        data{
             name
             count
            }
          }
        }
`;
