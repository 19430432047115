import { useEffect, useState } from "react";
import HelpmatePasswordSetup from "./helpmate/HelpmateResetPassword";
import AdminResetPassword from "./admin/AdminResetPassword";

const ResetPassword = (): JSX.Element => {
  const[isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateScreen = () => {
    setDesktop(window.innerWidth > 650);
  }

  useEffect(() => {
    window.addEventListener('resize', updateScreen);
    return () => window.removeEventListener('resize', updateScreen);
  })

  return (
    <div>
      {isDesktop ? (
        <div>
          <AdminResetPassword />
        </div>
      ) : (
        <div>
         <HelpmatePasswordSetup />
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
