// import { Navigate, Outlet } from "react-router";

// const useAuth = () => {
//   const user = localStorage.getItem("user");
//   if (user) {
//     return true;
//   } else {
//     return false;
//   }
// };

// const ProtectedRoutes = () => {
//   const auth = useAuth();

//   return auth ? <Outlet /> : <Navigate to="/Login" />;
// };

// export default ProtectedRoutes;

import { ROUTES } from 'constants/index';
import { Navigate } from 'react-router-dom';
import { useStore } from 'store';
import { useLocation } from 'react-router-dom';
import { RoleTypes } from 'enums';
// import { history } from './history';
import {
  b2b_Dashboard,
  helpmate_Module,
  customer_Success_manager,
  recruiter_or_hr,
  admin_module,
} from 'constants/index';
import { iState } from 'types';
import GoBack from 'components/GoBack/GoBack';

function ProtectedRoute({
  component: Component,
}: {
  component: any;
}): JSX.Element {
  const [state]: [iState] = useStore();
  const { pathname } = useLocation();

  const { user } = state || {};
  const { loggedIn } = user || {};
  const { role } = loggedIn || {};

  const isLoggedIn = loggedIn ? true : false;
  if (!isLoggedIn) return <Navigate to={ROUTES.login} />;

  const isBusiness = role === RoleTypes.BusinessUser || false;
  const isHelpmate = role === RoleTypes.Helpmate || false;
  const isAdmin = role === RoleTypes.Admin || false;
  const isCustomer_success_manager =
    role === RoleTypes.customer_success_manager || false;
  const isrecruiter_or_hr =
    role === RoleTypes.recruiter_or_hr || false;
  const helpmatePaths = Object.values(helpmate_Module);
  const businessPaths = Object.values(b2b_Dashboard);
  const CustomerManagerPaths = Object.values(customer_Success_manager);
  const recruiterManagerPaths = Object.values(recruiter_or_hr);
  const adminPaths = Object.values(admin_module);

  if (isBusiness && businessPaths.includes(pathname)) {
    return Component;
  } else if (isHelpmate && helpmatePaths.includes(pathname)) {
    return Component;
  } else if (
    isCustomer_success_manager &&
    CustomerManagerPaths.includes(pathname)
  ) {
    return Component;
  }
  else if (
    isrecruiter_or_hr &&
    recruiterManagerPaths.includes(pathname)
  ) {
    return Component;
  }
  else if (
    isAdmin &&
    adminPaths.includes(pathname)
  ) {
    return Component;
  }
  else
    return (
      <div>
        <GoBack />
      </div>
    );
}

export default ProtectedRoute;
