import { Query } from 'config';
import { IndividualHelpmateAnalyticsQuery, helpmateAnalyticsQuery } from 'graphql/recruiterOrHr';
import { toaster } from 'helpers';
import {
  iGraphqlResponse,
  iHelplmateAnalyticsResponse,
  iHelpmateAnalyticsData,
  iIndividualHelpmateAnalyticsArgs,
  iIndividualHelpmateAnalyticsData,
  iIndividualHelpmateAnalyticsResponse,
} from 'types';

export const getHelpmateAnalytics = async (
  isLoading
): Promise<iHelpmateAnalyticsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(helpmateAnalyticsQuery)
    ) as iGraphqlResponse<iHelplmateAnalyticsResponse>);
    isLoading(false);
    return response.data.helpmateAnalytics.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const individualHelpmateAnalyticsApi = async (
  args: iIndividualHelpmateAnalyticsArgs,
  isLoading
): Promise<iIndividualHelpmateAnalyticsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(IndividualHelpmateAnalyticsQuery, args)
    ) as iGraphqlResponse<iIndividualHelpmateAnalyticsResponse>);
    isLoading(false);
    return response.data.individualHelpmateAnalytics.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error);
    return null;
  }
};
