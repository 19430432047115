import { gql } from 'apollo-boost';

export enum User {
  GetAllPositions = 'getAllPositions',
  AddUpdateBusinessUser = 'addUpdateBusinessUser',
  IsValidEmail = 'isValidEmail',
  CreateHelpmate = 'createHelpmate',
  UpDateNotification = 'upDateNotification',
}

export const addUpdateBusinessUserMutation = gql`
    mutation ${User.AddUpdateBusinessUser}(
      $id: String
      $name: String!
      $email: String!
      $mobileNumber: String!
      $position: String!
      $businessId: String!
      $profilePic: String
      $location: locationInput
      $settings: settingInput
    ){
      ${User.AddUpdateBusinessUser}(
        id: $id
        name: $name
        email: $email
        mobileNumber: $mobileNumber
        position: $position
        businessId: $businessId
        profilePic: $profilePic
        location: $location
        settings: $settings
      ){
        message
        statusCode
        data{
          id
          name
          email
          profilePic
          mobileNumber    
        }
      }
    }

`;

export const isValidEmailQuery = gql`
  query ${User.IsValidEmail}($email: String){
    ${User.IsValidEmail}(email: $email) {
      message
      statusCode
      data{
        id
        name
        email
        position{
          id
          name
        }
        role
        profilePic
        mobileNumber
        businessId
        location{
          address
        }
      }
    }
  } 
`;

export const getAllPositionsQuery = gql`
  query ${User.GetAllPositions}{
    ${User.GetAllPositions} {
      message
      statusCode
      data{
        id
        name
      }
    }
  } 
`;

export const createHelpmateMutation = gql`
    mutation ${User.CreateHelpmate}(
      $name: String!
      $lastName: String!
      $email: String!
      $mobileNumber: String!
      $referedFrom: String!
      $settings: settingInput
      $mile: Int
    ){
      ${User.CreateHelpmate}(
        name: $name
        email: $email
        lastName: $lastName
        mobileNumber: $mobileNumber
        referedFrom: $referedFrom
        settings: $settings
        mile: $mile
      ){
        message
        statusCode
        data{
          id
          email
        }
      }
    }

`;

export const updateNotificationMutation = gql`
    mutation ${User.UpDateNotification}(
      $id: String
      $notificationEmail: Boolean
      $chatNotificationEmail: Boolean
      $notificationPush: Boolean
      $textMessage: Boolean
      $pohoneCall: Boolean
      $chatNotificationPush: Boolean
      $jobReminder2Hours: Boolean
      $jobReminder72Hours: Boolean
      $jobReminder24Hours: Boolean
    ){
      ${User.UpDateNotification}(
        id: $id
        notificationEmail: $notificationEmail
        chatNotificationEmail: $chatNotificationEmail
        notificationPush: $notificationPush
        textMessage: $textMessage
        pohoneCall: $pohoneCall
        chatNotificationPush: $chatNotificationPush
        jobReminder2Hours: $jobReminder2Hours
        jobReminder72Hours: $jobReminder72Hours
        jobReminder24Hours: $jobReminder24Hours
      ){
        message
        statusCode
        data{
          id
          notificationEmail
          notificationPush
          textMessage
          pohoneCall
          chatNotificationEmail
          chatNotificationPush
        }
      }
    }
`;
