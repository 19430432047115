import { Mutation, Query } from 'config';
import {
  adminIssuesListQuery,
  filterAdminIssueQuery,
  getALlHelpmateIssuesQuery,
  getIssueDetailQuery,
  helpamteIssuesListQuery,
  saveHelpmateRatingMutation,
  saveIssueMutation,
  updateIssueMutation,
} from '../graphql';
import {
  GetIssueDetailResponse,
  iAdminIssuesListArgs,
  iAdminIssuesListArs,
  iAdminIssuesListData,
  iAdminIssuesListResponse,
  iFilterAdminIssueArgs,
  iFilterAdminIssueData,
  iFilterAdminIssueResponse,
  iGetAllHelpmateIssuesArgs,
  iGetAllHelpmateIssuesData,
  iGetAllHelpmateIssuesResponse,
  iGetIssueDetailData,
  iGraphqlResponse,
  iHelpamteIssuesListArgs,
  iHelpamteIssuesListData,
  iHelpamteIssuesListResponse,
  iSaveHelpmateRating,
  iSaveHelpmateRatingArgs,
  iSaveIssueArgs,
  iSaveIssueData,
  iSaveIssueResponse,
  iupdateIssueArgs,
  iUpdateIssueData,
  iupdateIssueResponse,
  saveHelpmateRatingResponse,
} from 'types';
import { toaster } from 'helpers';

export const getAllHelpmateIssues = async (
  args: iGetAllHelpmateIssuesArgs,
  isLoading
): Promise<Array<iGetAllHelpmateIssuesData> | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getALlHelpmateIssuesQuery,
      args
    )) as iGraphqlResponse<iGetAllHelpmateIssuesResponse>);
    isLoading(false);
    return response.data.getAllHelpmateIssues.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const saveHelpmateRating = async (
  args: iSaveHelpmateRatingArgs,
  isLoading
): Promise<iSaveHelpmateRating | null> => {
  try {
    isLoading(true);
    const response = await ((await Mutation(
      saveHelpmateRatingMutation,
      args
    )) as iGraphqlResponse<saveHelpmateRatingResponse>);
    isLoading(false);
    return response.data.saveHelpmateRating;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    return null;
  }
};

export const saveIssue = async (
  args: iSaveIssueArgs,
  isLoading
): Promise<iSaveIssueData | null> => {
  try {
    isLoading(true);
    const response = await ((await Mutation(
      saveIssueMutation,
      args
    )) as iGraphqlResponse<iSaveIssueResponse>);
    isLoading(false);
    return response.data.saveIssue.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const adminIssuesListApi = async (
  args: iAdminIssuesListArs,
  isLoading
): Promise<iAdminIssuesListData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      adminIssuesListQuery,
      args
    )) as iGraphqlResponse<iAdminIssuesListResponse>);
    isLoading(false);
    return response.data.adminIssuesList.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    return null;
  }
};

export const adminHelpmateIssuesListApi = async (
  args: iHelpamteIssuesListArgs,
  isLoading
): Promise<iHelpamteIssuesListData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      helpamteIssuesListQuery,
      args
    )) as iGraphqlResponse<iHelpamteIssuesListResponse>);
    isLoading(false);
    return response.data.helpamteIssuesList.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    return null;
  }
};

export const getIssueDetailApi = async (
  args: iAdminIssuesListArgs,
  isLoading
): Promise<iGetIssueDetailData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getIssueDetailQuery,
      args
    )) as iGraphqlResponse<GetIssueDetailResponse>);
    isLoading(false);
    return response.data.getIssueDetail.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    return null;
  }
};

export const updateIssueApi = async (
  args: iupdateIssueArgs,
  isLoading
): Promise<iUpdateIssueData | null> => {
  try {
    isLoading(true);
    const response = await ((await Mutation(
      updateIssueMutation,
      args
    )) as iGraphqlResponse<iupdateIssueResponse>);
    isLoading(false);
    return response.data.updateIssue.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error);
    toaster.error(error.message);
    return null;
  }
};

export const filterAdminIssueApi = async (
  args: iFilterAdminIssueArgs,
  isLoading
): Promise<iFilterAdminIssueData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      filterAdminIssueQuery,
      args
    )) as iGraphqlResponse<iFilterAdminIssueResponse>);
    isLoading(false);
    return response.data.filterAdminIssue.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};
