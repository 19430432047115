import { Mutation, Query } from 'config';
import {
  iGraphqlResponse,
  iGetAllPositionsResponse,
  iGetAllPositionsData,
  iAddPersonalArgs,
  iAddUpdateBusinessUserData,
  iAddPersonalResponse,
  iCreateHelpmateArgs,
  iCreateHelpmateData,
  iCreateHelpmateResponse,
  iUpdateNotificationArgs,
  iUpDateNotificationData,
  iUpDateNotificationResponse,
} from 'types';
import {
  addUpdateBusinessUserMutation,
  createHelpmateMutation,
  getAllPositionsQuery,
  updateNotificationMutation,
} from '../graphql';

export const addUpdateBusinessUser = async (
  args: iAddPersonalArgs,
  isLoading
): Promise<iAddUpdateBusinessUserData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(addUpdateBusinessUserMutation, args)
    ) as iGraphqlResponse<iAddPersonalResponse>);
    isLoading(false);
    return response.data.addUpdateBusinessUser.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
      // toaster.error(error.message);
    return null;
  }
};

export const getAllPositions =
  async (): Promise<Array<iGetAllPositionsData> | null> => {
    try {
      const response = (
        await Query(getAllPositionsQuery)
      ) as iGraphqlResponse<iGetAllPositionsResponse>;
      return response.data.getAllPositions.data;
    } catch (error: any) {
      // captureException(error);
      return null;
    }
  };

export const createHelpmate = async (
  args: iCreateHelpmateArgs,
  isLoading
): Promise<iCreateHelpmateData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(createHelpmateMutation, args)
    ) as iGraphqlResponse<iCreateHelpmateResponse>);
    isLoading(false);
    return response.data.createHelpmate.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    //   toaster.error(error.message);
    return null;
  }
};

export const updateNotification = async (
  args: iUpdateNotificationArgs
): Promise<iUpDateNotificationData | null> => {
  try {
    const response = await ((
      await Mutation(updateNotificationMutation, args)
    ) as iGraphqlResponse<iUpDateNotificationResponse>);
    return response.data.upDateNotification.data;
  } catch (error: any) {
    console.log(error);
    return null;
  }
};
