import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import {
  GetFilteredManagersResponse,
  iActiveAnalyticsData,
  iActiveAnalyticsResponse,
  iAddManagerArgs,
  iAddManagerData,
  iAddManagerResponse,
  iBusinessAnalyticsData,
  iBusinessAnalyticsResponse,
  iBusinessRevenuArgs,
  iBusinessRevenuData,
  iBusinessRevenuResponse,
  iDemandedSkills,
  iDemandedSkillsResponse,
  iGetClientIndustries,
  iGetClientIndustriesResponse,
  iGetFilteredManagersArgs,
  iGetFilteredManagersData,
  iGetManagerDetailsArgs,
  iGetManagerDetailsData,
  iGetManagerDetailsResponse,
  iGraphqlResponse,
  iSearchManagerArgs,
  iSearchManagerData,
  iSearchManagerResponse,
  iTotalBookedHoursArgs,
  iTotalBookedHoursData,
  iTotalBookedHoursResponse,
  iTotalHoursAnalyticsArgs,
  iTotalHoursAnalyticsData,
  iTotalHoursAnalyticsResponse,
} from 'types';
import {
  addManagerMutation,
  getActiveAnalyticsQuery,
  getBusinessAnalyticsQuery,
  getBusinessRevenueQuery,
  getClientIndustriesQuery,
  getDemandedSkillsQuery,
  getFilteredManagersQuery,
  getManagerDetailsQuery,
  getTotalBookedHoursQuery,
  getTotalHoursAnalyticsQuery,
  searchManagerQuery,
} from '../graphql';

export const getDemandedSkills = async (
  isLoading
): Promise<iDemandedSkills | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getDemandedSkillsQuery)
    ) as iGraphqlResponse<iDemandedSkillsResponse>);
    isLoading(false);
    return response.data.demandedSkills;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getTotalHoursAnalytics = async (
  args: iTotalHoursAnalyticsArgs,
  isLoading
): Promise<iTotalHoursAnalyticsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getTotalHoursAnalyticsQuery, args)
    ) as iGraphqlResponse<iTotalHoursAnalyticsResponse>);
    isLoading(false);
    return response.data.totalHoursAnalytics.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getActiveAnalytics = async (
  isLoading
): Promise<iActiveAnalyticsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getActiveAnalyticsQuery)
    ) as iGraphqlResponse<iActiveAnalyticsResponse>);
    isLoading(false);
    return response.data.activeAnalytics.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getBusinessAnalytics = async (
  isLoading
): Promise<iBusinessAnalyticsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getBusinessAnalyticsQuery)
    ) as iGraphqlResponse<iBusinessAnalyticsResponse>);
    isLoading(false);
    return response.data.businessAnalytics.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getTotalBookedHours = async (
  args: iTotalBookedHoursArgs,
  isLoading
): Promise<iTotalBookedHoursData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getTotalBookedHoursQuery, args)
    ) as iGraphqlResponse<iTotalBookedHoursResponse>);
    isLoading(false);
    return response.data.totalBookedHours.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};

export const getBusinessRevenue = async (
  args: iBusinessRevenuArgs,
  isLoading
): Promise<iBusinessRevenuData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getBusinessRevenueQuery, args)
    ) as iGraphqlResponse<iBusinessRevenuResponse>);
    isLoading(false);
    return response.data.businessRevenu.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
      toaster.error(error.message);
    return null;
  }
};

export const searchManagerApi = async (
  args: iSearchManagerArgs,
  isLoading
): Promise<iSearchManagerData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(searchManagerQuery, args)
    ) as iGraphqlResponse<iSearchManagerResponse>);
    isLoading(false);
    return response.data.searchManager.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const addManagerApi = async (
  args: iAddManagerArgs,
  isLoading
): Promise<iAddManagerData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Mutation(addManagerMutation, args)
    ) as iGraphqlResponse<iAddManagerResponse>);
    isLoading(false);
    return response.data.addManager.data;
  } catch (error: any) {
    isLoading(false);
    // captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getManagerDetailsApi = async (
  args: iGetManagerDetailsArgs,
  isLoading
): Promise<iGetManagerDetailsData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getManagerDetailsQuery, args)
    ) as iGraphqlResponse<iGetManagerDetailsResponse>);
    isLoading(false);
    return response.data.getManagerDetails.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const getFilteredManagersApi = async (
  args: iGetFilteredManagersArgs,
  isLoading
): Promise<iGetFilteredManagersData | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getFilteredManagersQuery, args)
    ) as iGraphqlResponse<GetFilteredManagersResponse>);
    isLoading(false);
    return response.data.getFilteredManagers.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};


export const GetClientIndustriesApi = async (
  isLoading
): Promise<iGetClientIndustries | null> => {
  try {
    isLoading(true);
    const response = await ((
      await Query(getClientIndustriesQuery)
    ) as iGraphqlResponse<iGetClientIndustriesResponse>);
    isLoading(false);
    return response.data.getClientIndustries;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};