import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import {
  iGraphqlResponse,
  iCreateBusinessArgs,
  iCreateBusinessResponse,
  iCreateBusinessData,
  iGetAllIndustriesData,
  iGetAllIndustriesResponse,
  iGetJobsByBusinessIdArgs,
  iGetJobsByBusinessIdResponse,
  iGetAllBusinessResponse,
  iBusinessData,
  iGetFilteredJobsArgs,
  iGetFilteredJobsResponse,
  iGetJobDetailsArgs,
  iGetJobDetailsData,
  iGetJobDetailsResponse,
  iSkillsData,
  iGetAllSkillsResponse,
  iCreateJobArgs,
  iCreateJob,
  iCreateJobResponse,
  iGetBusinessDetailsArgs,
  iGetBusinessDetailsData,
  iGetBusinessDetailsResponse,
  iUpdateBusinessArgs,
  iUpdateBusinessResponse,
  iUpdateBusinessData,
  iGetAllIssuesResponse,
  iGetAllIssuesData,
  iOnboardBusinessData,
  iOnboardBusinessResponse,
  iOnboardBusinessArgs,
  iListTimeSheetArgs,
  iListTimeSheetData,
  ListTimeSheetResponse,
  iGetSearchedJobsData,
  iGetFilteredJobsDetails,
  iListNotificationsArgs,
  iGetAllBusinessNotificationsResponse,
  iGetAllBusinessNotifiData,
  iReadNotificationsArgs,
  iReadNotificationsResponse,
  iReadNotifications,
  iGetAllIssuesResponseArgs,
  iGetFilteredBusinessArgs,
  iGetFilteredBusinessResponse,
  iGetFilterBusinessData,
  iSearchBusinessArgs,
  iSearchBusinessData,
  iSearchBusinessResponse,
  iDeleteTimeSheetResponseArgs,
  iDeleteTimeSheet,
  iDeleteTimeSheetResponse,
} from 'types';
import {
  getJobsByBusinessIdQuery,
  createBusinessMutation,
  getAllBusinessQuery,
  getAllIndustriesQuery,
  getFilteredJobsQuery,
  getJobDetailsQuery,
  getAllSkillsQuery,
  createJobMutation,
  getBusinessDetailsQuery,
  updateBusinessMutation,
  getAllBusinessIssueQuery,
  updateBusinessStatus,
  listTimeSheetQuery,
  getAllBusinessNotifiQuery,
  getFilteredBusinessQuery,
  readNotificationsMutation,
  searchBusinessQuery,
  deleteTimeSheetMutation,
} from '../graphql';

export const getAllIndustries =
  async (): Promise<Array<iGetAllIndustriesData> | null> => {
    try {
      const response = (await Query(
        getAllIndustriesQuery
      )) as iGraphqlResponse<iGetAllIndustriesResponse>;
      return response.data.getAllIndustries.data;
    } catch (error: any) {
      // captureException(error);
      return null;
    }
  };

export const createBusiness = async (
  args: iCreateBusinessArgs,
  isLoading
): Promise<iCreateBusinessData | null> => {
  try {
    isLoading(true);
    const response = await ((await Mutation(
      createBusinessMutation,
      args
    )) as iGraphqlResponse<iCreateBusinessResponse>);
    isLoading(false);
    return response.data.createBusiness.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    //   toaster.error(error.message);
    return null;
  }
};

export const getJobsByBusinessId = async (
  args: iGetJobsByBusinessIdArgs,
  isLoading
): Promise<iGetSearchedJobsData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getJobsByBusinessIdQuery,
      args
    )) as iGraphqlResponse<iGetJobsByBusinessIdResponse>);
    isLoading(false);
    return response.data.getSearchedJobs.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};

export const getFilteredJobs = async (
  args: iGetFilteredJobsArgs,
  isLoading
): Promise<iGetFilteredJobsDetails | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getFilteredJobsQuery,
      args
    )) as iGraphqlResponse<iGetFilteredJobsResponse>);
    isLoading(false);
    return response.data.getFilteredJobs.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};

export const getAllBusiness = async (
  isLoading
): Promise<Array<iBusinessData> | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getAllBusinessQuery
    )) as iGraphqlResponse<iGetAllBusinessResponse>);
    isLoading(false);
    return response.data.getAllBusiness.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error?.message);
    console.log(error?.message);
    return null;
  }
};

export const getFilteredBusiness = async (
  args: iGetFilteredBusinessArgs,
  isLoading
): Promise<iGetFilterBusinessData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getFilteredBusinessQuery,
      args
    )) as iGraphqlResponse<iGetFilteredBusinessResponse>);
    isLoading(false);
    return response.data.filterBusiness.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error?.message);
    console.log(error?.message);
    return null;
  }
};

export const getAllSkills = async (): Promise<Array<iSkillsData> | null> => {
  try {
    const response = (await Query(
      getAllSkillsQuery
    )) as iGraphqlResponse<iGetAllSkillsResponse>;

    return response.data.getAllSkills.data;
  } catch (error: any) {
    return null;
  }
};

export const getJobDetailsbyId = async (
  args: iGetJobDetailsArgs,
  isLoading
): Promise<iGetJobDetailsData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getJobDetailsQuery,
      args
    )) as iGraphqlResponse<iGetJobDetailsResponse>);
    isLoading(false);
    return response.data.getJobDetails.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    return null;
  }
};

export const createJob = async (
  args: iCreateJobArgs
): Promise<iCreateJob | null> => {
  try {
    const response = await ((await Mutation(
      createJobMutation,
      args
    )) as iGraphqlResponse<iCreateJobResponse>);
    return response.data.createJob;
  } catch (error: any) {
    console.log(error.message);
    //   toaster.error(error.message);
    return null;
  }
};

export const getBusinessDetails = async (
  args: iGetBusinessDetailsArgs,
  isLoading
): Promise<iGetBusinessDetailsData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getBusinessDetailsQuery,
      args
    )) as iGraphqlResponse<iGetBusinessDetailsResponse>);
    isLoading(false);
    return response.data.getBusinessDetails.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    return null;
  }
};

export const updateBusiness = async (
  args: iUpdateBusinessArgs
): Promise<iUpdateBusinessData | null> => {
  try {
    const response = await ((await Mutation(
      updateBusinessMutation,
      args
    )) as iGraphqlResponse<iUpdateBusinessResponse>);
    return response.data.updateBusiness;
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};

export const getAllBusinessIssues = async (
  args: iGetAllIssuesResponseArgs,
  isLoading
): Promise<iGetAllIssuesData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getAllBusinessIssueQuery,
      args
    )) as iGraphqlResponse<iGetAllIssuesResponse>);
    isLoading(false);
    return response.data.getAllIssues.data;
  } catch (error: any) {
    return null;
  }
};

export const approveBusiness = async (
  args: iOnboardBusinessArgs,
  isLoading
): Promise<iOnboardBusinessData | null> => {
  try {
    isLoading(true);
    const response = await ((await Mutation(
      updateBusinessStatus,
      args
    )) as iGraphqlResponse<iOnboardBusinessResponse>);
    isLoading(false);
    return response.data.onboardBusiness.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    //   toaster.error(error.message);
    return null;
  }
};

export const listTimeSheet = async (
  args: iListTimeSheetArgs,
  isLoading
): Promise<iListTimeSheetData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      listTimeSheetQuery,
      args
    )) as iGraphqlResponse<ListTimeSheetResponse>);
    isLoading(false);
    return response.data.listTimeSheet.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const listNotifications = async (
  args: iListNotificationsArgs,
  isLoading
): Promise<Array<iGetAllBusinessNotifiData> | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      getAllBusinessNotifiQuery,
      args
    )) as iGraphqlResponse<iGetAllBusinessNotificationsResponse>);
    isLoading(false);
    return response.data.getAllBusinessNotifi.data;
  } catch (error: any) {
    isLoading(false);
    //   captureException(error);
    console.log(error.message);
    // toaster.error(error.message);
    return null;
  }
};

export const readNotifications = async (
  args: iReadNotificationsArgs
): Promise<iReadNotifications | null> => {
  try {
    const response = await ((await Mutation(
      readNotificationsMutation,
      args
    )) as iGraphqlResponse<iReadNotificationsResponse>);
    return response.data.readNotifications;
  } catch (error: any) {
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};

export const searchBusinessApi = async (
  args: iSearchBusinessArgs,
  isLoading
): Promise<iSearchBusinessData | null> => {
  try {
    isLoading(true);
    const response = await ((await Query(
      searchBusinessQuery,
      args
    )) as iGraphqlResponse<iSearchBusinessResponse>);
    isLoading(false);
    return response.data.searchBusiness.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    return null;
  }
};

export const deleteTimeSheetApi = async (
  args: iDeleteTimeSheetResponseArgs
): Promise<iDeleteTimeSheet | null> => {
  try {
    const response = await ((await Mutation(
      deleteTimeSheetMutation,
      args
    )) as iGraphqlResponse<iDeleteTimeSheetResponse>);
    return response.data.deleteTimeSheet;
  } catch (error: any) {
    console.log(error.message);
    toaster.error(error.message);
    return null;
  }
};
