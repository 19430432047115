import { AppBar } from "@mui/material";
import HelpmateLogo from "../../../icons/HelpmateLogo";

const BaseAuthentication = () => {
  return (
    <AppBar position="static" sx={{boxShadow: "none", backgroundColor: "#0777F7"}}>
      <div style={{ margin:"17px 24px 17px 24px",}}>
        <HelpmateLogo />
      </div>
    </AppBar>
  );
};

export default BaseAuthentication;
