import { iState } from 'types';

export const initialState: iState = {
  business: {
    allIndustries: [],
    businessId: '',
    applicants: [],
    businessUserId: '',
    slots: '',
    adminBusinessId: '',
    allBusiness: [],
    filterIds: [],
    jobDetailsId: '',
    allSkills: [],
    jobSkills: [],
    companyLogo: '',
    updateBusinessDetails: false,
    role: '',
  },
  chat: {
    interlocutorId: '',
    inboxId: '',
    toProfilePic: '',
    toPersonLastName: '',
    toPersonFName: '',
    updateConversation: false,
    updateInboxList: false,
    userId: '',
  },
  user: {
    isLoggedIn: false,
    allPositions: [],
    personalId: '',
    profilPic: '',
    emailId: '',
    helpmateEmailId: '',
    loggedIn: null,
    helpmateId: '',
    managerId: '',
    showTimeBoxPopup: false,
    profilePicture: '',
    helpmateBgPic: '',
    helpmateIssueId: '',
    jobStatus: '',
    jobCount: '',
    helpmateCity: '',
    loggedInUserName: '',
    helpmateData: {
      id: '',
      name: '',
      lastName: '',
      mobileNumber: '',
      email: '',
      profilePic: '' || undefined,
      bgPic: '',
      about: '',
    },
    helpmateSkills: [],
    envName: "",
  },
  stripe: {
    stripeCustomerId: '',
    clientSecretKey: '',
    paymentDetail: '',
  },
};
