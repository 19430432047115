import { gql } from 'apollo-boost';

export enum Business {
  CreateBusiness = 'createBusiness',
  GetAllIndustries = 'getAllIndustries',
  GetSearchedJobs = 'getSearchedJobs',
  GetAllBusiness = 'getAllBusiness',
  GetFilteredJobs = 'getFilteredJobs',
  GetAllSkills = 'getAllSkills',
  GetBusinessDetails = 'getBusinessDetails',
  UpdateBusiness = 'updateBusiness',
  GetAllIssues = 'getAllIssues',
  OnboardBusiness = 'onboardBusiness',
  ListTimeSheet = 'listTimeSheet',
  GetAllBusinessNotifi = 'getAllBusinessNotifi',
  FilterBusiness = 'filterBusiness',
  ReadNotifications = 'readNotifications',
  SearchBusiness = 'searchBusiness',
  DeleteTimeSheet = 'deleteTimeSheet',
}

export const createBusinessMutation = gql`
    mutation ${Business.CreateBusiness}(
      $name: String!
      $email: String!
      $user: String
      $industry: String!
      $noOfEmployees: Int
      $mobileNumber: String
      $about: String
      $logo: String
      $location: locationInput
      ){
        ${Business.CreateBusiness}(
            name: $name
            email: $email
            user: $user
            industry: $industry
            noOfEmployees: $noOfEmployees
            mobileNumber: $mobileNumber
            about: $about
            logo: $logo
            location: $location
          ){
            message
            statusCode
            data {
              id
            }
        }
    }
`;

export const getAllIndustriesQuery = gql`
  query ${Business.GetAllIndustries}{
    ${Business.GetAllIndustries} {
      message
      statusCode
      data{
        id
        name
      }
    }
  } 
`;

export const getJobsByBusinessIdQuery = gql`
  query ${Business.GetSearchedJobs}(
    $businessId: String!
    $searchKey: String!
    $skip: Int
    $limit: Int
    $status: [String]
  ){
    ${Business.GetSearchedJobs}(
      businessId: $businessId
      searchKey: $searchKey
      skip: $skip
      limit: $limit
      status: $status
    ){
      message
      statusCode
      data{
        count
        jobList{
        status
        id
        jobName,
          reportingTo,
          sectionName,
          payment,
          position{
            id
            name
          },
          noOfWorkers,
          yearOfExperience,
          description,
          skills,
          equipment,
          covid19,
          startDate,
          endDate,
          totalLaborHours,
          dayOfWeek,
          pointContact,
          userId,
          createdAt,
          business{
            id
            name
            logo
          },
          location{
            lat
            long
            id
          address
          }
        }
      }
    }
  }
`;

export const getFilteredJobsQuery = gql`
query ${Business.GetFilteredJobs}(
  $businessId: String!
  $filterType: String!
  $filter: String!
  $skip: Int
  $limit: Int
  $status: [String]
){
  ${Business.GetFilteredJobs}(
    businessId: $businessId
    filterType: $filterType
    filter: $filter
    skip: $skip
    limit: $limit
    status: $status
  ){
    message
    statusCode
    data{
      count
      jobList{
      status
      id
      jobName,
        reportingTo,
        sectionName,
        payment,
        position{
          id
          name
        },
        noOfWorkers,
        yearOfExperience,
        description,
        skills,
        equipment,
        covid19,
        startDate,
        endDate,
        totalLaborHours,
        dayOfWeek,
        pointContact,
        userId,
        business{
          id
          name
        },
        location{
          lat
          long
          id
        address
        }
      }
    }
  }
}
`;

export const getAllBusinessQuery = gql`
    query ${Business.GetAllBusiness}{
      ${Business.GetAllBusiness} {
        message
        statusCode
        data {
			  id
        name
        mobileNumber
        email
        user{
          id
          name
        }
          status
          createdAt
        }
      }
    }
`;

export const getFilteredBusinessQuery = gql`
    query ${Business.FilterBusiness}(
      $filterType: FilterType!
      $skip: Int
      $limit: Int
    ){
      ${Business.FilterBusiness}(
        filterType: $filterType
        skip: $skip
        limit: $limit
      ){
        statusCode
    message
    data{
      count
      businessList{
        id
        name
        mobileNumber
        email
        user{
          id
          name
        }
        status
        createdAt
        }
      }
      }
    }
`;

export const getAllSkillsQuery = gql`
  query ${Business.GetAllSkills}{
    ${Business.GetAllSkills} {
      message
      statusCode
      data{
        id
        name
      }
    }
  } 
`;

export const getBusinessDetailsQuery = gql`
  query ${Business.GetBusinessDetails}(
    $id: String! 
  ){
    ${Business.GetBusinessDetails}(
      id: $id
    ){
      message
      statusCode
      data {
        id
        name
        mobileNumber
        email
        logo
        noOfEmployees
        status
        createdAt
        updateAt
        location {
          id
          address
        }
        about
        user{
          email
          id
          name
          lastName
          mobileNumber
          position{
            id
            name
          }
          role
          profilePic
          opt
          otpExpiryTime
          settings{
            id
        notificationEmail
        notificationPush
        textMessage
        pohoneCall
        chatNotificationEmail
        chatNotificationPush
        jobReminder2Hours
        jobReminder24Hours
        jobReminder72Hours
        createdAt
        updateAt
          }
          location{
            id
            address
          }
        }
      }
    }
  }
`;

export const updateBusinessMutation = gql`
  mutation ${Business.UpdateBusiness}(
    $id: String!
    $name: String!
    $email: String!
    $userId: String!
    $noOfEmployees: Int!
    $mobileNumber: String!
    $about: String!
    $logo: String
    $location: locationInput1!
  ){
    ${Business.UpdateBusiness}(
      id: $id
      name: $name
      email: $email
      userId: $userId
      noOfEmployees: $noOfEmployees
      mobileNumber: $mobileNumber
      about: $about
      logo: $logo
      location: $location
    ){
      statusCode
      message
      data{
        id
      }
    }
  }
`;

export const getAllBusinessIssueQuery = gql`
  query ${Business.GetAllIssues}($businessId: String, $searchKey: String!, $skip: Int, $limit: Int){
    ${Business.GetAllIssues}(businessId: $businessId, searchKey: $searchKey, skip: $skip, limit: $limit) {
      message
      statusCode
      data{
        count
        issurList{
        id
        reporter_user_id
        reporter_email_id
        problem
        status
        createdAt
        updateAt
        deletedAt
        job{
          id
          jobName
          position{
            id
            name
          }
          business{
            id
            name
          }
          location{
            id
            address
          }
        }
      }
      }
    }
  } 
`;

export const updateBusinessStatus = gql`
  mutation ${Business.OnboardBusiness}($id: String!){
    ${Business.OnboardBusiness}(id: $id){
      message
      statusCode
      data{
        id
      }
    }
  }
`;

export const listTimeSheetQuery = gql`
  query ${Business.ListTimeSheet}
  (
    $jobId: String!
    $helpmateId: String!
  ){
    ${Business.ListTimeSheet}(
      jobId: $jobId
      helpmateId: $helpmateId
    ) {
      message
      statusCode
      data{
        week
        startDate
        endDate
        totalHours
        totalPay
        timeSheet {
          dayname
          workdate
          daytotal
          position
          address
          shift {
            id
            jobId
            helpmate
            startTime
            endTime
            hoursWorked
            status
            createdAt
            updateAt
          }
        }
      }
    }
  } 
`;

export const getAllBusinessNotifiQuery = gql`
  query ${Business.GetAllBusinessNotifi}(
    $businessId: String!
  ){
    ${Business.GetAllBusinessNotifi}(
      businessId: $businessId
    ){
      statusCode
      message
      data{
        id
        title
        description
        jobId
        helpmateId
        businessId
        notificationType
        is_read
        createdAt
        updateAt
      }
    }
  }
`;

export const readNotificationsMutation = gql`
  mutation ${Business.ReadNotifications}($ids: [String]!){
    ${Business.ReadNotifications}(ids: $ids){
      statusCode
      message
    }
  }
`;

export const searchBusinessQuery = gql`
  query ${Business.SearchBusiness}(
    $searchKey: String
    $status: String
    $skip: Int
    $limit: Int
  ){
    ${Business.SearchBusiness}(
      searchKey: $searchKey
      status: $status
      skip: $skip
      limit: $limit
    ){
      message
      statusCode
      data{
          count
          businessList{
            id
            name
            mobileNumber
            email
            user{
              id
              name
              lastName
            }
            updateAt
            status
            createdAt
          }
      }
    }
  }
`;

export const deleteTimeSheetMutation = gql`
  mutation ${Business.DeleteTimeSheet}($timeSheetId: String){
    ${Business.DeleteTimeSheet}(timeSheetId: $timeSheetId){
      statusCode
      message
    }
  }
`;