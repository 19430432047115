import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

const FormTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: `4px 4px 4px 4px`,
        },
      },
    }
  })(TextField);
  
  export default FormTextField;