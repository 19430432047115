import { gql } from 'apollo-boost';

export enum OnBoarding {
  Login = 'login',
  ValidateOTP = 'validateOTP',
  SetPassword = 'setPassword',
  SendOTP = `sendOTP`,
  ResetPassword = `resetPassword`,
  SetPasswordNew = 'setPasswordNew',
}

export const loginMutation = gql`
    mutation ${OnBoarding.Login}(
      $email: String!
      $password: String!
    ){
      ${OnBoarding.Login}(
        email: $email
        password: $password
      ){
        message
        statusCode
        data {
          jwtToken 
        }
      }
    }
`;

export const validateOTPMutation = gql`
    mutation ${OnBoarding.ValidateOTP}($email: String, $mobileNumber: String, $otp: Int!, $updateFrom: String! ){
      ${OnBoarding.ValidateOTP}(email: $email,mobileNumber: $mobileNumber, otp: $otp, updateFrom: $updateFrom){
        message
        statusCode
      }
    }
`;

export const setPasswordMutation = gql`
    mutation ${OnBoarding.SetPassword}(
      $email: String!
      $password: String!
      $type: String
      $token: String
    ){
      ${OnBoarding.SetPassword}(
        email: $email
        password: $password
        type: $type
        token: $token
      ){
        message
        statusCode
      }
    }
`;

export const resetPasswordQuery = gql`
    mutation ${OnBoarding.SetPasswordNew}(
      $email: String!
      $password: String!
      $type: String
    ){
      ${OnBoarding.SetPasswordNew}(
        email: $email
        password: $password
        type: $type
      ){
        message
        statusCode
      }
    }
`;


export const sendOtpMutation = gql`
    mutation ${OnBoarding.SendOTP}(
      $type: String!
      $to: String!
      $updateFrom: String!
    ){
      ${OnBoarding.SendOTP}(
        type: $type
        to: $to
        updateFrom: $updateFrom
      ){
        message
        statusCode
      }
    }
`;

export const resetPasswordMutation = gql`
    mutation ${OnBoarding.ResetPassword}(
      $email: String!
      $oldPassword: String!
      $newPassword: String!
    ){
      ${OnBoarding.ResetPassword}(
        email: $email
        oldPassword: $oldPassword
        newPassword: $newPassword
      ){
        statusCode
        message
        data{
            id
        }
      }
    }
`;
