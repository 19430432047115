import { useState } from 'react';
import HideEyeIcon from 'icons/HideEyeIcon';
import EyeIcon from '../icons/EyeIcon'

const usePasswordToggle =() => {
  const [visible, setVisibility] =useState(false);
  const [confirmVisible, setconfirmVisible] = useState(false);

  const Icon = (
    <>
    {visible && 
        <EyeIcon 
          onClick={() => setVisibility(visibility => !visibility)} />}
    {!visible &&      
  <HideEyeIcon 
          onClick={() => setVisibility(visibility => !visibility)} />}
            </>
  );
  const confirmIcon = (
    <>
    {confirmVisible && 
        <EyeIcon 
          onClick={() => setconfirmVisible(confirmVisible => !confirmVisible)} />}
    {!confirmVisible &&      
  <HideEyeIcon 
          onClick={() => setconfirmVisible(confirmVisible => !confirmVisible)} />}
            </>
  );

  const PasswordInputType = visible ? "text": "password";

  return [PasswordInputType, Icon];

 const PasswordInputType1 = confirmVisible ? "text": "password";

  return [PasswordInputType1, confirmIcon];


  const confirmIcon1 = (
    <>
    {confirmVisible && 
        <EyeIcon 
          onClick={() => setconfirmVisible(confirmVisible => !confirmVisible)} />}
    {!confirmVisible &&      
  <HideEyeIcon 
          onClick={() => setconfirmVisible(confirmVisible => !confirmVisible)} />}
            </>
  );

  const PasswordInputType2 = confirmVisible ? "text": "password";

  return [PasswordInputType2, confirmIcon1];
    }

    


export default usePasswordToggle;